import {ConfigService} from "../../resources/services/ConfigService";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {NitTools} from "../../resources/classes/NursitTools";
import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {UserService} from "../../resources/services/UserService";
import {Questionnaire} from "../../resources/elements/questionnaire/questionnaire";
import * as environment from "../../../config/environment.json";
import {CiwQuestionnaireItem} from "../../resources/elements/questionnaire/ciw-questionnaire-item";
import {PatientChangeNotifier} from "../../resources/services/PatientChangeNotifier";
import {QuestionnaireResponse, Tools} from "../../resources/classes/FhirModules/Fhir";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {PatientService} from "../../resources/services/PatientService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

const moment = require('moment');

export class NursingVisitsProtocol extends FormBaseClass {
    __statusConfigs: IPvPStatus[];

    __currentStatus: IPvPStatus;

    nextStatus: IPvPStatus;
    previousStatus: IPvPStatus;
    _route: string = 'nursingvisit';
    _buttonText = 'Neues PVP';
    questionnaireName: string;
    questionnaireView: Questionnaire;
    showCreateButton: boolean = false;
    showStornoButton: boolean = false;
    userCanChangeStatus: boolean = false;

    get currentStatus(): IPvPStatus {
        return this.__currentStatus;
    }

    public static NursingVisitStatusSystem: string = "NursingVisitStatus";
    public static NursingVisitColorSystem: string = "NursingVisitColor";
    public static NursingVisitResubmissionSystem: string = "NursingVisitResubmissionDate";

    public static ExpirationDate(patient: PatientItem) : Date {
        const resubmissionExtension = patient.flags.code.coding.find(o => o.system.endsWith(NursingVisitsProtocol.NursingVisitResubmissionSystem));
        if (resubmissionExtension && resubmissionExtension.code) {
            return moment(resubmissionExtension.code).toDate();
        }

        return undefined;
    }

    public static IsExpired(patient: PatientItem): boolean {
        const resubmissionDate = this.ExpirationDate(patient);
        return (resubmissionDate && moment(resubmissionDate).isBefore(new Date()));
    }

    public static GetSignalColor(patient: PatientItem, resubmissionColor : string = '#c35add') : string {
        let result = 'red';
        if (!patient?.flags?.code?.coding)
            return result;

        const colorExtension = patient.flags.code.coding.find(o => o.system.endsWith(NursingVisitsProtocol.NursingVisitColorSystem));
        if (!colorExtension || !colorExtension.code) return result;
        result = colorExtension.code.trim();

        if (this.IsExpired(patient))
            result = resubmissionColor;

        return result;
    }

    override async createButtonClicked(preset?): Promise<any> {
        const result = await super.createButtonClicked(preset);
        Tools.UpdateAuthor(result, UserService.Practitioner);
        if (!result.extension) result.extension = [];
        result.extension.push({
            url : "http://nursit-institute.com/fhir/StructureDefinition/Creator",
            valueCoding: {
                code: UserService.Practitioner.id,
                display: `${UserService.UserFirstName}, ${UserService.UserFirstName}`
            }
        });

        return result;
    }

    async setStatus(newStatus: IPvPStatus) {
        if (newStatus.code === 'save') {
            RuntimeInfo.IsLoading = true;
            try {
                await this.fhirService.bundle([this.patient.flags, this.response], HTTPVerb.put, BundleType.transaction, false);
                PatientService.AddQuestionnaireResponse(this.patient, this.response, true);
                this.hasChanges = false;
            }
            catch (e) {
                console.warn(e);
            }
            finally {
                RuntimeInfo.IsLoading = false;
            }

            return;
        }

        if (this.response) {
            const responseBackup = JSON.stringify(this.response);
            const flagsBackup = JSON.stringify(this.patient.flags);

            if (!this.response.extension) this.response.extension = [];

            //const backup = JSON.stringify(this.response);
            let existing = this.response.extension.find(o => o.url.endsWith('/statusEx'));

            if (newStatus) {
                if (!existing) {
                    existing = {url: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/QuestionnaireResponse/statusEx`};
                    this.response.extension.push(existing);
                }

                existing.valueCoding = {code: newStatus.code, display: newStatus.display};
            } else {
                // remove the status
                this.response.extension.splice(this.response.extension.indexOf(existing), 1);
            }

            this.response.status = newStatus.responseStatus;

            const toUpdate: any[] = [this.response];
            if (this.patient?.flags?.code?.coding) {
                const codings: any[] = this.patient.flags.code.coding;

                let statusExtension = codings.find(o => o.system.endsWith(NursingVisitsProtocol.NursingVisitStatusSystem));
                if (!statusExtension) {
                    statusExtension = {system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/${NursingVisitsProtocol.NursingVisitStatusSystem}`};
                    codings.push(statusExtension);
                }
                statusExtension.code = newStatus.code;
                statusExtension.display = newStatus.display;

                let statusColorExtension = codings.find(o => o.system.endsWith(NursingVisitsProtocol.NursingVisitColorSystem));
                if (!statusColorExtension) {
                    statusColorExtension = {system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/${NursingVisitsProtocol.NursingVisitColorSystem}`};
                    codings.push(statusColorExtension);
                }
                statusColorExtension.code = newStatus.signalColor;

                if (this.response && this.resubmissionLinkId) {
                    let resubmissionExtension = codings.find(o => o.system.endsWith(NursingVisitsProtocol.NursingVisitResubmissionSystem));

                    const resubmissionDate = QuestionnaireResponse.GetResponseItemValueByLinkId(this.response, this.resubmissionLinkId, undefined);
                    if (resubmissionDate) {
                        if (!resubmissionExtension) {
                            resubmissionExtension = {system: `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/${NursingVisitsProtocol.NursingVisitResubmissionSystem}`};
                            codings.push(resubmissionExtension);
                        }

                        resubmissionExtension.code = moment(resubmissionDate).toJSON();
                    } else if (resubmissionExtension) {
                        codings.splice(codings.indexOf(resubmissionExtension), 1);
                    }
                }

                toUpdate.push(this.patient.flags);
            }

            if (responseBackup != JSON.stringify(this.response) ||
                flagsBackup != JSON.stringify(this.patient.flags))
            {
                if (ConfigService.Debug)
                    console.debug("Updating Fhir with", toUpdate);

                await this.fhirService.bundle(toUpdate, HTTPVerb.put, BundleType.transaction, false);
                this.patientService.addQuestionnaireResponse(this.patient, this.response, true);
                this.hasChanges = false;
            }
        }

        this.__currentStatus = newStatus;

        this.readNextAndPriorStatus();
        this.updateFormGroupsQualifications();

        PatientChangeNotifier.Notify(this.patient, this.patient.flags);
    }

    get statusConfigs(): IPvPStatus[] {
        return this.__statusConfigs;
    }

    set statusConfigs(states: IPvPStatus[]) {
        this.__statusConfigs = states;
        this.readCurrentStatusEx();
    }

    readNextAndPriorStatus() {
        this.nextStatus = undefined;
        this.previousStatus = undefined;

        if (this.currentStatus) {
            // find out what the next status is, when allowed
            if (this.currentStatus.allowNextStatus && this.currentStatus.nextStatus) {
                this.nextStatus = this.statusConfigs.find(o => o.code === this.currentStatus.nextStatus);
            }

            // found out what was the previous status, when allowed
            if (this.currentStatus.allowPreviousStatus && this.currentStatus.previousStatus) {
                this.previousStatus = this.statusConfigs.find(o => o.code === this.currentStatus.previousStatus);
            }
        }
    }

    readCurrentStatusEx() {
        this.__currentStatus = undefined;

        if (this.statusConfigs && this.response && this.response.extension) {
            // get the current status from the response
            const statusCodeExtension = this.response.extension.find(o => o.url.endsWith('/statusEx'));
            if (!statusCodeExtension) {
                this.setStatus(this.statusConfigs[0]);
            } else {
                this.setStatus(this.statusConfigs.find(o => o.code === statusCodeExtension.valueCoding.code) || this.statusConfigs[0]);
            }

            this.readNextAndPriorStatus();
        }
    }

    override async afterResponseChanged(response: any) : Promise<void> {
        await super.afterResponseChanged(response);

        this.readCurrentStatusEx();
        this.updateFormGroupsQualifications();
    }

    updateFormGroupsQualifications() {
        this.mayBeEdited = false;
        this.readonly = true;
        this.showSaveButton = false;

        const items = <CiwQuestionnaireItem[]>this.questionnaire.item;
        for (const item of items) {
            item.qualificationFulfilled = false;
        }

        this.userCanChangeStatus = this.checkUserMayChangeCurrentStatus(this.currentStatus);
        if (this.userCanChangeStatus) {
            this.mayBeEdited = true;
            this.readonly = false;
            this.showSaveButton = true;


            // when the user may change the status, he may or not be able to edit various fields. So reevaluate the qualification(s).
            for (const item of items.filter(o => o.qualifications && o.qualifications.length > 0)) {
                if (UserService.UserHasQualification(item.qualifications)) {
                    item.qualificationFulfilled = true;
                }
            }
        }
    }

    afterLoaded() {
        super.afterLoaded();

        this.taskQueue.queueTask(() => {
            console.warn('After loaded');

            this.readCurrentStatusEx();

            this.updateFormGroupsQualifications();

            this.isLoading = false;
        });
    }


    mayChangeCurrentStatus: boolean = true;

    checkUserMayChangeCurrentStatus(forStatus?: IPvPStatus): boolean {
        if (!forStatus) forStatus = this.currentStatus;

        if (forStatus && forStatus.statusChangeRequiresQualification) {
            if (!UserService.UserHasQualification(forStatus.statusChangeRequiresQualification)) {
                return false;
            }
        }

        return true;
    }

    async changePVPStatus(newStatus: string) {
        const tmpStatus = this.statusConfigs.find(o => o.code === newStatus);
        const tmpMayChangeStatus = this.checkUserMayChangeCurrentStatus(tmpStatus);

        if (!tmpMayChangeStatus) {
            const qualiArray = tmpStatus.statusChangeRequiresQualification.join(this.i18n.tr('or'));
            if (!confirm(`Wenn Sie den Status auf "${tmpStatus.display}" ändern, werden Sie selber den Status nicht mehr ändern können sondern nur noch die Qualifikation(en) \n${qualiArray}.\n\nSind Sie sicher?\n`))
                return;
        }

        this.mayChangeCurrentStatus = tmpMayChangeStatus;
        await this.setStatus(tmpStatus); // updates the status in the response too ..
        this.readNextAndPriorStatus();
        this.updateFormGroupsQualifications();
        // .. TODO: update the response on the server and re-evaluate all stuff
        /* this.selectResponseId(null)
        .then(() => {
            this.selectResponseId(rId)
            .then(() => {
                this.readNextAndPriorStatus();
            })
        }) */

        // alert(`Previous: ${this.previousStatus?.code}, Current: ${this.currentStatus.code}, Next: ${this.nextStatus?.code}, QR-status will be: ${this.currentStatus.responseStatus}`);
    }

    resubmissionLinkId: string = undefined;
    routeConfig : IFormSetting;

    async attached() {
        if (ConfigService.Debug) window["pvp"] = this;

        this.isLoading = true;
        await super.attached();
        this.isLoading = true;

        await ConfigService.LoadConfigOverride(PatientItem.LastLoadedPatient?.ward);
        this.routeConfig = ConfigService.GetFormSettings(this.route);

        if (this.routeConfig) {
            if (this.routeConfig.settings?.qualificationsNeededForNewDocument) {
                this.showCreateButton = UserService.UserHasQualification(this.routeConfig.settings.qualificationsNeededForNewDocument);
            } else this.showCreateButton = false;

            if (this.routeConfig.settings?.responseStornoRequiresQualification) {
                this.showStornoButton = UserService.UserHasQualification(this.routeConfig.settings.responseStornoRequiresQualification);
            } else this.showStornoButton = false;

            if (this.routeConfig.settings?.states) {
                this.statusConfigs = this.routeConfig.settings.states;
            } else {
                alert("No settings.states found. Please configure correctly to use this.");
            }

            if (this.routeConfig.settings?.resubmissionLinkId) {
                this.resubmissionLinkId = this.routeConfig.settings.resubmissionLinkId;
            }
        }
    }
}

export interface IPvPStatus {
    display: string,
    code: string,
    signalColor: string,
    nextStatus?: string,
    previousStatus?: string,
    allowPreviousStatus: boolean,
    allowNextStatus: boolean,
    responseStatus: ('in-progress' | 'completed' | 'amended' | 'entered-in-error' | 'stopped'),
    statusChangeRequiresQualification: string[]
}


export class __X__NursingVisitsProtocol extends FormBaseClass {
    async attached() {
        this.buttonText = 'Neues Protokoll';
        this.route = 'nursingvisit';

        await super.attached(); // goes to FormBaseClass
    }

    afterLoaded() {
        super.afterLoaded();
        console.warn("AfterLoaded reached");
        // WHAT A BULLSHIT!
        //this.showSaveButton = !!(UserService.Practitioner.qualification.find(o=>o.code === 'PPL') || UserService.Practitioner.qualification.find(o=>o.code === 'SSL'));
    }
}
