import { App } from "app";
import { fhirEnums } from "resources/classes/fhir-enums";
import { IFormSetting } from "resources/classes/IFormSettings";
import { NitTools } from "resources/classes/NursitTools";
import { PatientItem } from "resources/classes/Patient/PatientItem";
import { BasicForm } from "resources/elements/BasicForm";
import { FormBaseClass } from "resources/elements/FormBaseClass";
import { IPprAgeSetting } from "resources/elements/patient-overview/IPprAgeSetting";
import { PprInfo } from "resources/elements/patient-overview/PprInfo";
import { ConfigService } from "resources/services/ConfigService";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { ReportService } from "resources/services/ReportService";
const moment  =  require("moment");

export class ppr2 extends FormBaseClass {
    pprAgeSetting : IPprAgeSetting;
    config : IFormSetting;
    loadingDone : boolean = false;
    autoId : string; // a replacement for autoloadId Property, because the handling has to be different here

    override async printButtonClicked(isMultiPrint?: boolean, openPfd?: boolean) {
        if (['completed', 'amended'].indexOf(this.response.status) == -1) {
            this.dialogMessages.dialog(
                this.i18n.tr("ppr_print_only_finalized", { questionnaireTitle: this.questionnaire.title }),
                this.i18n.tr("information"), 
                this.i18n.tr("ok"), '', false);
            return;
        }

        await super.printButtonClicked(isMultiPrint, openPfd);
    }

    override async beforeSave(): Promise<any> {
        this.response.authored = moment(new Date()).toJSON();
    }


    async loadPPRpatient(patient: PatientItem) {
        // avoid double loading
        if (!this.config?.settings?.ageRanges) {
            console.warn('No PPR-AgeSetting specified. Please check the source in patientChanged()!\nFalling back to default values from config');            
        }

        // use the default values provided in the route config as default
        this.pprAgeSetting = {
            $_INFO: "Default route values for PPR from route config",
            questionnaireName : this.config?.questionnaireName,
            isoEnabled: true,
            from: -1,
            to: -1
        }

        if (!this.patient) {
            console.warn('No Patient provided, bailing out');

            return;
        }

        if (!this.config?.settings?.ageRanges) {
            console.warn('No config.settings.ageRanges found in Route "PPR2.0"');

            return;
        }
        
        for (const setting of <IPprAgeSetting[]>this.config.settings.ageRanges) {
            if (typeof setting.from != "number" || typeof setting.to != "number" || !setting.questionnaireName) {
                console.warn('PPR2.0: invalid age range specified in: ', setting);
                continue;
            }

            if (this.patient.days > setting.from && this.patient.days <= setting.to) {
                this.pprAgeSetting = NitTools.Clone(setting);
                if (ConfigService.Debug)
                    console.debug('FOUND PPR Setting:', this.pprAgeSetting, this.config.settings);

                break;
            }
        }

        const defaultStates = [fhirEnums.QuestionnaireResponseStatus.completed, 
            fhirEnums.QuestionnaireResponseStatus.amended,
            fhirEnums.QuestionnaireResponseStatus.inProgress
        ];

        this.questionnaireName = this.pprAgeSetting.questionnaireName;
        this.questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.questionnaireName);

        if (!this.questionnaire) {
            this.responses = [];
            this.response = undefined;
            alert(`Configured Questionnaire "${this.pprAgeSetting.questionnaireName}" could not be found`);

            return;
        }
        
        BasicForm.pageTitle = this.questionnaire?.title;

        this.responses = QuestionnaireService.GetResponsesOfType(this.patient, this.questionnaire.id, defaultStates);
        this.response = QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaire.id, defaultStates);
        
        this.hasDefaultResponseItems = false;
        this.loadingDone = true;
        this.isLoading = false;
        
        this.documentLoaded = true;
        this.showSaveButton = true;
        this.buttonText = this.i18n.tr("add_document");
        this.report = this.questionnaire.name;

        if (ConfigService.Debug)
            window["ppr"] = this;
    }

    override async afterLoadedData() {
        await super.afterLoadedData();
        this.loadPPRpatient(this.patient);
    }

    override checkGroupVisibilites(item) {
        super.checkGroupVisibilites(item);

        this.checkSaveButton();        
    }

    override async afterSave(response: any): Promise<void> {
        await super.afterSave(response);
        this.patient.ppr = new PprInfo(this.patient, this.i18n);

        const listItem = App?.PatientList?.patients?.find(o=>o?.encounterId == this.patient?.encounterId);
        
        if (!listItem) return;        
        listItem.ppr = this.patient.ppr;
    }

    updatePreviousResponse() {
        if (NitTools.IsArray(this.responses) && this.response) {
            const currentIndex = this.responses.indexOf(this.response);
            if (currentIndex >= 1) {
                this.previousResponse = this.responses[currentIndex -1];
            } else {
                this.previousResponse = undefined;
            }
        } else {
            this.previousResponse = undefined;
        }
    }

    override async afterResponseChanged(response: any): Promise<void> {
        await super.afterResponseChanged(response);
        this.forcedReadonly = !this.isFromToday(response);

        this.updatePreviousResponse();

        this.showPrintButton = !!ReportService.ReportServer;
        return Promise.resolve();
    }

    isFromToday(response) {
        if (!response) return false;
        const created = moment(response.authored).toObject();
        const now = moment(new Date()).toObject();

        return created.years == now.years && created.months == now.months && created.date == now.date;
    }

    override async createButtonClicked(preset) {
        const lastResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaire?.id, [fhirEnums.QuestionnaireResponseStatus.completed,
            fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.inProgress]);

        if (this.isFromToday(lastResponse)) {
            const msg = this.i18n.tr('ppr_today_response_exists', { questionnaireTitle : this.questionnaire.title });
            this.dialogMessages.dialog(msg, this.i18n.tr('error'), this.i18n.tr('ok'), undefined, false);

            return;
        }

        await super.createButtonClicked(preset);
    }

    checkSaveButton() {
        if (!this.response?.authored) {
            return;
        }
        
        const $saveButton = document.querySelector("save-button");
        if (!$saveButton) return;

        const parentDiv = <HTMLDivElement>$saveButton.parentElement;
        // do some cleanup
        let warningSpan = <HTMLDivElement>parentDiv?.querySelector("div.no-save-message");
        warningSpan?.remove();

        // Hide the save-button from the user, when the ppr info is not from today
        if (this.isFromToday(this.response)) {
            $saveButton.classList.remove("hidden");            
        } else {
            $saveButton.classList.add("hidden");

            if (parentDiv) {
                warningSpan = document.createElement('div');
                warningSpan.innerHTML = 'Vortags PPR';
                warningSpan.classList.add('no-save-message');
                warningSpan.style.marginTop = '0.5em';
                warningSpan.style.color = 'rgb(255, 113, 113)';
                warningSpan.style.cursor = 'no-drop';

                parentDiv.appendChild(warningSpan);
            }
        } // only saveable on the day it was created
    }

    async attached() {
        this.route = "PPR2.0";
        this.reAssignResponses = false;

        await super.attached();
        this.dateChangeable = false; // remove the ability to edit the date when in tab view mode
        
        if (this.autoId) {
            window.setTimeout(() => {
                this.hasChanges = false;
                this.selectedid = this.autoId;
                
                this.autoId = undefined;
            }, 250);
        }
    }

    async activate(e) {
        this.route = "PPR2.0";
        this.reAssignResponses = false;
        this.autoId = e.responseId;
        this.showPdfButton = !!ReportService.ReportServer; // allow pdf generation
        this.showTrashcan = false;
        
        this.config = ConfigService.GetFormSettings("PPR2.0");

        this.pprAgeSetting = this.pprAgeSetting = {
            $_INFO: "Default route values for PPR from route config",
            questionnaireName : this.config.questionnaireName,
            isoEnabled: true,
            from: -1,
            to: -1
        };

        if (e.id)
            this.patient = await PatientItem.Load(e.id, false);
        else
            this.patient = PatientItem.LastLoadedPatient;

//        await this.loadPPRpatient(this.patient);
    }
}
