import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import { HomeList } from '../list';
import { NitTools } from '../../resources/classes/NursitTools';
import { DialogMessages } from '../../resources/services/DialogMessages';
import {autoinject, bindable, TaskQueue} from "aurelia-framework";
import { PatientItem } from "../../resources/classes/Patient/PatientItem";
import { IFormSetting } from "../../resources/classes/IFormSettings";
import { BasicForm } from "../../resources/elements/BasicForm";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { PatientService } from "resources/services/PatientService";
import { LocationService } from "resources/services/LocationService";
import { RuntimeInfo } from "../../resources/classes/RuntimeInfo";
import { AnalyzeService } from "../../resources/services/analyzeService";
import { DialogService } from "aurelia-dialog";
import { ReportService } from "resources/services/ReportService";
import { UserService } from "resources/services/UserService";
import { Router } from "aurelia-router";
import { ModalEncounterTypeChoice } from 'resources/elements/patient-overview/modal-encounter-type-choice';
import { ModalRelationSelect } from "../../resources/elements/modal-relation-select";
import { FhirService } from "../../resources/services/FhirService";
import { ModalRelationActions } from '../../resources/elements/modal-relation-actions';
import { fhirEnums } from '../../resources/classes/fhir-enums';
import {HttpClient} from "aurelia-http-client";
import SystemHeaders from "../../resources/classes/SystemHeaders";
const moment = require('moment');

@autoinject
export class PatientDetails {
    /* get patient(): PatientItem {
        return PatientItem.SelectedPatient;
    } */
   @bindable patient : PatientItem;

    configService: ConfigService;
    config: IFormSetting;
    i18n: I18N;

    subDelegationEnabled : boolean = false;

    @bindable encounterId: string = undefined;
    get isBaby(): boolean {
        return this.patient ? this.patient.isBaby : false;
    }

    get isMother(): boolean {
        return this.patient ? this.patient.isMother : false;
    }

    taskQueue: TaskQueue;
    showToDoList: boolean = true;
    showShiftReport: boolean = true;
    dischargePct: number = 0;
    patientService: PatientService;
    locationService: LocationService;
    analyzeService: AnalyzeService;
    dialogService: DialogService;
    router: Router;
    showRiskOverview: boolean = true;
    showSpiSpider: boolean = true;
    showSpiSpider2: boolean = false;
    showSignalBar: boolean = true;
    showGrafixx: boolean = true;
    showDischarge: boolean = true;
    showPPR: boolean = true;    
    loading: boolean = true;
    showPlanning: boolean = true;
    public spiderTitle: string = "Pflegeindex";
    encounterTypeChoices: any[];
    encounterTypeChoiceEnabled: boolean = false;
    hasError: boolean = false;
    errorMessage: string = undefined;
    @bindable displayRelatedActionsButton : boolean;
    //private _isResizing: boolean = false;

    constructor(taskQueue: TaskQueue, i18n: I18N, patientService: PatientService, locationService : LocationService, analyzeService: AnalyzeService, dialogService: DialogService, router: Router) {
        this.taskQueue = taskQueue;
        this.router = router;
        this.i18n = i18n;
        this.patientService = patientService;
        this.locationService = locationService;
        this.analyzeService = analyzeService;
        this.dialogService = dialogService;

        this.updateVisibilities();

        if (ConfigService.Debug)
            window["details"] = this;
    }

    private updateVisibilities() {
        this.subDelegationEnabled = this.isModuleVisible('subdelegation');
        // visibility from form-settings, property "enabled"
        this.showPlanning = this.isModuleVisible('planning');
        this.showGrafixx = this.isModuleVisible('grafixx');
        this.showDischarge = this.isModuleVisible('discharge');
        this.showPPR = this.isModuleVisible('PPR2.0');

        // visibility from features.[name] = true/false
        this.showRiskOverview = this.getFeatureVisibility("riskOverview");
        this.showSpiSpider = this.getFeatureVisibility("spiSpider");

        this.showSpiSpider2 = this.getFeatureVisibility("spiSpider2", false);

        this.showSignalBar = this.getFeatureVisibility("signalBar");
    }

    get isDoctor(): boolean {
        return UserService.UserRole === 'doctor';
    }

    get isTrainee(): boolean {
        return UserService.UserRole === 'trainee';
    }

    getFeatureVisibility(feature: string, defaultValue = true): boolean {
        const cfgShowFeature = RuntimeInfo.Features[feature];
        if (typeof cfgShowFeature === "boolean")
            return <boolean>cfgShowFeature;

        return defaultValue;
    }

    async encounterIdChanged() {
        /// if (newVal === this.encounterId) return;
        await this.loadPatient();
    }

    isModuleVisible(moduleRoute: string) {
        let cfg = ConfigService.GetFormSettings(moduleRoute);
        if (!cfg) return true;
        if (typeof cfg.enabled === "boolean") return cfg.enabled;
        else return true;
    }

    async loadPatient(): Promise<any> {
        if (!this.encounterId) {
            RuntimeInfo.CurrentWardId = undefined;
            this.patient = undefined;

            return;
        }

        RuntimeInfo.IsLoading = true;
        this.loading = true;

        try {
            this.patient = await PatientItem.Load(this.encounterId);
            await this.locationService.fetch();

            PatientItem.SelectedPatient = this.patient;
            await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

            this.spiderTitle = this.i18n.tr("spi_short");

            const setting = ConfigService.GetFormSettings('details');
            if (setting && setting.settings && setting.settings["riskSpiderTitle"]) {
                const newTitle = this.i18n.tr(setting.settings["riskSpiderTitle"]);
                if (newTitle) {
                    this.spiderTitle = newTitle;
                }
            }

            this.updateVisibilities();

            RuntimeInfo.IsLoading = false;
            this.hasError = false;
            this.errorMessage = undefined;

            if (ReportService.ReportServer) {
                try {
                    if (!sessionStorage.getItem('messageData')) {
                        let response = await new HttpClient().configure((o => o.withTimeout(2500))).get(ReportService.BaseUrl + '/Info/GetUnread/' + UserService.UserName + '?_=' + new Date().valueOf());
                        let js;
                        if (response.content) js = response.content;
                        else js = JSON.parse(response.response);
                        if (js && js.length > 0) {
                            sessionStorage.setItem('messages', this.i18n.tr('unread_news').replace("%COUNT%", js.length));
                            sessionStorage.setItem('messageData', JSON.stringify(js));
                        }
                    }
                } catch (e) {
                    sessionStorage.removeItem('messages');
                    sessionStorage.removeItem('messageData');
                    console.warn(e.message || JSON.stringify(e));
                }
            }

            let messagesString = sessionStorage.getItem('messages');
            if (messagesString) {
                let js = JSON.parse(sessionStorage.getItem('messageData'));
                if (js.length > 0) {
                    RuntimeInfo.ShowInfo(messagesString, false, () => {
                        RuntimeInfo.HideInfo();
                        this.router.navigateToRoute('info');
                    });
                }
            }

            await this.getEncounterTypeSettings();

            return Promise.resolve();
        } catch (error) {
            this.errorMessage = error.message || error;
            this.hasError = true;
            RuntimeInfo.IsLoading = false;
            console.warn(this.errorMessage);

            return Promise.reject(this.errorMessage);
        }
        finally {
            this.loading = true;
        }
    }

    encounterTypeMdiClass: string;
    relationIconTitle: string;
    displayEncounterTypeIcons : boolean = false;

    async getEncounterTypeSettings() {
        this.encounterTypeChoiceEnabled = false;
        this.displayEncounterTypeIcons = false;
        this.encounterTypeMdiClass = undefined;

        this.relationIconTitle = '';
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

        if (!ConfigService.EncounterTypeChoice.enabled)
            return;

        const choice = ConfigService.EncounterTypeChoice;
        if (!choice.enabled)
            return;

        this.encounterTypeChoiceEnabled = true;
        this.encounterTypeChoices = choice.choices;
        this.displayEncounterTypeIcons = ConfigService.EncounterTypeChoice.displayIcons;

        // Hier muss nach dem alter geschaut werden... < 1 Jahr = Kind
        this.encounterTypeMdiClass = '';
        if (this.encounterTypeChoiceEnabled) {
            PatientItem.ReadRelations(this.patient);
            this.displayRelatedActionsButton = this.isBaby || this.isMother;
            this.encounterTypeMdiClass = this.isBaby ? 'mdi-face' : 'mdi-pregnant-woman';
        }

        /* const relations = this.patient.flags.code.coding.filter(o => o.system.startsWith(PatientItem.RelationSystem));
        if (relations) {
            this.relationIconTitle = relations.map(o => o.display).join(',\n');
        } */
    }

    async storeRelations(patients: PatientItem[]) {
        try {
            RuntimeInfo.IsLoading = true;

            const fs = new FhirService();
            RuntimeInfo.ShowInfo("Aktualisiere Verwandschaft..", false);

            const ps = patients.filter(o => typeof (o) !== "undefined");
            await fs.bundle(ps.map(o => o.flags), fhirEnums.HTTPVerb.put, fhirEnums.BundleType.transaction);

            for (const p of PatientItem.Patients)
                PatientItem.ReadRelations(p);

            PatientItem.ReadRelations(this.patient);
            this.displayRelatedActionsButton = this.isMother || this.isBaby;

            await this.refreshEverything();

            PatientItem.SelectedPatient = await PatientItem.Load(this.patient.encounterId);

            PatientItem.HighlightRelatedEncounters(this.patient);
            RuntimeInfo.ShowInfo("Verwandschaften aktualisiert", true);
        }
        catch (ex) {
            console.warn(ex);
        }
        finally {
            RuntimeInfo.IsLoading = false;
            RuntimeInfo.HideInfo();
        }
    }

    async displayRelatedActions() {
        if (!this.patient)
            return;

        const patient : any = <any>await Fhir.Rest.Get(`Patient/${this.patient.id}`);
        this.patient.link = patient.link;
        PatientItem.ReadRelations(this.patient);

        if ((this.isBaby && !this.patient.motherAssigned) || (this.isMother && !this.patient.childrenAssigned)) {
            this.searchForRelated();
            return;
        }

        const children: any[] = [];
        if (this.isMother) {
            for (const childId of this.patient.childrenPatients) {
                const child = PatientItem.Patients.find(o=>o.id === childId);
                if (child) {
                    children.push({display: child.display, encounterId: child.id});
                }
            }
        }

        let mother: PatientItem;
        if (this.isBaby && this.patient.motherPatient) {
            mother = PatientItem.Patients.find(o => o.id === this.patient.motherPatient);
        }

        const that = this;
        this.dialogService.open({
            viewModel: ModalRelationActions,
            model: {
                title: 'Aktion ausw&auml;len',
                patient: this.patient,
                children: children,
                mother: mother,
                relation: this.isBaby ? 'Mutter' : 'Kind',
                selectedId: undefined
            },
            lock: true,
            centerHorizontalOnly: true
        })
            .whenClosed(async result => {
                if (result.wasCancelled || !result.output)
                    return;
                else if (result.output === 'selectRelation')
                    that.searchForRelated();
                else if (result.output.startsWith('removeChild:')) {
                    DialogMessages.Dialog(this.dialogService,
                        "Wollen Sie wirklich die Kind-Zuordnung entfernen?", that.i18n.tr("confirm"),
                        that.i18n.tr("yes"), that.i18n.tr("no"), true)
                        .whenClosed(async cr => {
                            if (cr.wasCancelled)
                                return;

                            const removeId = result.output.split(':')[1];

                            // remove child from mother
                            await PatientItem.RemoveChild(this.patient, removeId);
                            // refresh display
                            await this.refreshEverything();
                        });
                }
                else {
                    const targetPatient = PatientItem.Patients.find(o=>o.id === result.output);
                    if (targetPatient && targetPatient.encounterId)
                        that.router.navigateToRoute('encounter', { id: targetPatient.encounterId });
                }
            });
    }

    /**
     * Display a search box to assign a link between baby/mother
     */
    searchForRelated() {
        let possible: PatientItem[];
        if (this.isBaby) {
            possible = PatientItem.Patients.filter(o => o.gender === 'female' && o.isMother && o.encounterId !== this.encounterId);
        } else if (this.isMother) {
            possible = PatientItem.Patients.filter(o=>o.isBaby && !o.motherAssigned);
        }

        this.dialogService.open({
            viewModel: ModalRelationSelect,
            model: {
                title: this.i18n.tr(this.isBaby ? 'mother_select' : 'child_select'),
                patientName: this.patient.display,
                patients: possible,
                relation: this.i18n.tr(this.isBaby ? 'mother' : 'child'),
                selectedId: undefined
            },
            lock: true,
            centerHorizontalOnly: true
        })
            .whenClosed(async result => {
                if (result.wasCancelled)
                    return;

                const target: PatientItem = possible.find(o => o.id == result.output);
                if (!target) {
                    alert("Patient nicht gefunden. Bitte debuggen!");
                    return;
                }

                if (this.isBaby) { // target is the mother
                    // add the relation to the baby in the mother..
                    await PatientItem.CreateMotherChildLink(target, this.patient);
                } else { // target is the baby
                    // assign the mother to the child
                    await PatientItem.CreateMotherChildLink(this.patient, target);
                }

                await this.refreshEverything();
            });
    }

    async refreshEverything() {
        if (HomeList?.instance) {
            await HomeList.instance.refreshWard(true);
        }

        const patient : any = <any>await Fhir.Rest.Get(`Patient/${this.patient.id}`);
        this.patient.link = patient.link;
        this.patient.clearChildrenEncounter();
        this.patient.motherAssigned = false;

        PatientItem.ReadRelations(this.patient);
    }

    async displayEncounterType() {
        const updateAnalyzer = async (version: string) => {
            const isOther = version === "DEFAULT";
            if (isOther)
                version = "SEMPA";

            AnalyzeService.UpdateAnalyzerExtension(this.patient, this.patient.latestAssessment, version);
            this.patient.isBaby = version?.toUpperCase() === "SEMPA-KIDS";
            this.patient.isMother = !this.patient.isBaby && !isOther;

            const encounterTypeFlag = Fhir.Tools.GetOrCreateFlag(this.patient.flags, SystemHeaders.systemEncounterType, true);
            if (!isOther)
                encounterTypeFlag.code = this.patient.isBaby ? 'baby' : 'mother';
            else {
                encounterTypeFlag.code = 'other';
            }

            PatientItem.ReadRelations(this.patient);
            this.displayRelatedActionsButton = this.isMother || this.isBaby;
        };

        const createNewAssessment = (version: string) => {
            const analyzer = AnalyzeService.GetAnalyzer(version);
            if (!analyzer) {
                console.warn(`Analyzer "${version}" not found! Exiting without changes!`);
                return;
            }

            // generate clone of latest assessment..
            const newAssessment = NitTools.Clone(this.patient.latestAssessment);
            newAssessment.id = NitTools.Uid();
            // .. don't forget to add it to the patient ..
            PatientService.AddQuestionnaireResponse(this.patient, newAssessment);
            this.patient.latestAssessment = newAssessment;
            newAssessment.meta.lastUpdated = moment().toJSON();
            newAssessment.authored = moment().toJSON();
            newAssessment.author = {
                reference: `Practitioner/${UserService.Practitioner?.id}`
            };

            // .. set the correct analyzer ..
            updateAnalyzer(version);

            // and the run the analyzer ..
            console.warn("Neues Assessment wäre:", newAssessment);
            // analyzer.analyse(this.patient, this.patient.latestAssessment)
        };

        this.dialogService.open({
            viewModel: ModalEncounterTypeChoice,
            model: { options: this.encounterTypeChoices },
            centerHorizontalOnly: true,
            lock: true
        })
            .whenClosed(async result => {
                if (result.wasCancelled || !result.output) {
                    return;
                }

                const version = result.output;
                if (this.patient.latestAssessment) {
                    // when there is am existing assessment, then we need to create a new one with the updated analyzer flag
                    const messages = new DialogMessages(this.dialogService, this.i18n);
                    messages.dialog("Der Wechsel des Types benötigt die Erstellung eines neuen Assessments.<br />Wollen Sie dieses nun erstellen?", this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"), true)
                        .whenClosed(result => {
                            if (result.wasCancelled)
                                return;

                            createNewAssessment(version);
                        });
                } else {
                    // no assessment existent, so just update the flag
                    updateAnalyzer(version);
                    await this.storeRelations([this.patient]);
                }
            });
    }

    resizeDivs() {
        return; /*
        if (this._isResizing) return;
        this._isResizing = true;

        let controls = document.querySelectorAll('.container-fluid.fb .row.auto-max-height-row > [class*="col-"] > .user-control');
        let maxH = -1;
        controls.forEach(col => {
            if (col.parentElement.clientHeight > maxH) maxH = col.parentElement.clientHeight;
        })

        controls.forEach((control: HTMLDivElement) => {
            const sHeight = `${maxH}px`;
            control.parentElement.style.height = `${maxH + 14}px`;
            control.style.display = 'block';
            control.style.height = sHeight;

            const panel: HTMLDivElement = control.querySelector(".panel");
            if (panel) {
                let bodyHeight = maxH;
                const header: HTMLDivElement = panel.querySelector(".panel-heading");
                if (header) {
                    bodyHeight -= header.clientHeight;
                }

                const body: HTMLDivElement = panel.querySelector(".panel-body");
                if (body) {
                    body.style.height = `${bodyHeight - 2}px`;
                }
            }
        });

        this._isResizing = false; */
    }

    async attached() {
        document.body.classList.add("no-toolbar-window");

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Details);
        BasicForm.pageTitle = this.i18n.tr(this.config.title);

        let cfgPlanning = ConfigService.GetFormSettings(ConfigService.FormNames.Planning);
        this.showToDoList = cfgPlanning?.enabled;

        let cfgShiftReport = ConfigService.GetFormSettings('shift-report');
        this.showShiftReport = cfgShiftReport ? cfgShiftReport.enabled : cfgPlanning?.enabled;

        this.resizeDivs();
        $(window).on("resize", () => window.setTimeout(() => this.resizeDivs(), 100));
    }

    detached() {
        $(window).off("resize");
        document.body.classList.remove("no-toolbar-window");
    }

    async activate(params) {
        if (params && params.id) {
            this.encounterId = params.id;
            await this.loadPatient();
            //$(window).trigger("resize");

            this.taskQueue.queueTask(() => {
                $('[data-page="details"]').addClass("active");
            });
        } else this.encounterId = undefined;
    }
}
