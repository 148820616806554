import {autoinject, bindable} from "aurelia-framework";
import {Router} from "aurelia-router";
import {App} from "../../app";
import {LocationService} from "../../resources/services/LocationService";
import {DialogController, DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {NitTools} from "../../resources/classes/NursitTools";
import {FhirService} from "../../resources/services/FhirService";
import {ValidationController, ValidationControllerFactory, ValidationRules, Validator} from 'aurelia-validation';
import {BootstrapFormRenderer} from "../../resources/classes/bootstrap-form-renderer";
import {UserService} from "../../resources/services/UserService";
import * as moment from "moment";
import {ConfigService} from "../../resources/services/ConfigService";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";

@autoinject
/***
 * the backend class for handling adding a new patient and encounter resource
 */
export class AddPatient {
    router: Router;
    app: App;
    locationService: LocationService;
    controller: DialogController;
    service: DialogService;
    i18n: I18N;
    model: any;
    isMobile: boolean = false;
    wards: any[] = [];
    rooms: any[] = [];
    beds: any[] = [];
    loadingRooms: boolean = false;
    loadingBeds: boolean = false;
    admissionDate: Date = new Date();
    validator: Validator;
    @bindable wardId: string = '-';
    @bindable roomId: string = '-';
    bedId: string = '-';
    onlyActiveLocations: boolean = true;
    caseId: string;
    encounterStatus: ('planned'|'arrived'|'triaged'|'in-progress'|'onleave'|'finished'|'cancelled'|'entered-in-error'|'unknown') = 'in-progress';
    patientExists: boolean = false;
    existingPatientId: string;
    validationController: ValidationController;
    rulez;
    patientNameRestrictions;

    /** The Date-Picker options for the abp-datetime-picker */
    dpOptions = {
        format: RuntimeInfo.DateFormat,
        locale: RuntimeInfo.Language,
        showTodayButton: true,
        showClear: false,
        showClose: true,
        keepOpen: false,
        widgetPositioning: {
            horizontal: 'auto',
            vertical: 'auto'
        },
        focusOnShow: false
    };

    get firstName() {
        return this.model.name[0].given[0];
    }

    set firstName(value) {
        this.model.name[0].given[0] = value;
    }

    get lastName() {
        return this.model.name[0].family;
    }

    set lastName(value) {
        this.model.name[0].family = value;
    }

    get born(): Date {
        return this.model.birthDate ? new Date(this.model.birthDate) : null;
    }

    set born(value: Date) {
        this.model.birthDate = value ? moment(value).format('YYYY-MM-DD') : undefined;
    }

    get gender() {
        return this.model.gender;
    }

    set gender(value) {
        this.model.gender = value;
    }

    get patientIdentifier() {
        return;
        // if (!this.model?.identifier?.[0]?.value) return;
    }

    /**
     * Sets the identifier for the patientNumber
     */
    set patientIdentifier(value: string) {
        if (!value && this.model.identifier) {
            delete this.model.identifier;
            return;
        }

        if (!this.model.identifier) this.model.identifier = [];

        const system = PatientItem.GetPatientIdentifierSystem();

        let existing = this.model.identifier.find(o => o.system && o.system.toUpperCase() === system.toUpperCase());
        if (existing) {
            existing.value = value;
        } else {
            this.model.identifier.push({
                system: system,
                value: value
            });
        }
    }

    /**
     * fetches all child locations of the given location
     * @param locationId the id of the location to fetch child locations for
     * @returns an array of the child-locations of the given location
     */
    async loadLocationChildren(locationId: string) : Promise<{id: string, name:string, status: string}[]> 
    {
        let url = `Location?partof=${locationId}`;
        if (this.onlyActiveLocations) url += '&status=active';

        let _locations = <any[]>await new FhirService().fetch(url);
        return _locations.map(r => {
            return {id: r.id, name: r.name, status: r.status};
        });
    }

    /**
     * Checks whether a patient with the given identifier already exists
     */
    async checkPatientExists() {
        this.existingPatientId = undefined;
        this.patientExists = undefined;

        if (this.model.identifier) {
            const ident = this.model.identifier.find(o => o.system?.indexOf('/patientNumber') > -1);
            if (ident && ident.value) {
                const url = `Patient?identifier=${ident.value}&_summary=true`;
                const result = <any>await Fhir.Rest.Get(url);

                if (result && result.entry && result.entry.length > 0 && result.entry[0] && result.entry[0].resource) {
                    const patient = <any>result.entry[0].resource;
                    this.existingPatientId = patient.id;
                    this.patientExists = !!this.existingPatientId;

                    if (!this.firstName && patient.name[0].given)
                        this.firstName = patient.name[0].given?.join(' ');
                    if (!this.lastName && patient.name[0].family)
                        this.lastName = patient.name[0].family;
                    this.gender = patient.gender || 'unknown';
                    this.born = moment(patient.birthDate || new Date()).toDate();

                } else {
                    this.patientExists = false;
                }
            }
        }
    }

    /**
     * Creates all the resources needed for a new encounter/patient on the server
     */
    async createResources() {
        if (typeof this.patientExists === "undefined")
            await this.checkPatientExists();

        this.model.id = `${NitTools.Uid()}`; // 1a1000aa-00aa-00a0-00aa-00aa0aa00a01`;

        this.model.birthDate = moment(new Date(this.model.birthDate)).toJSON().split('T')[0];
        if (this.model.birthDate.indexOf('T') > -1) {
            this.model.birthDate = this.model.birthDate.split('T')[0];
        }

        if (this.patientExists && this.existingPatientId)
            this.model.id = this.existingPatientId;

        let enc: any = {
            id: `${NitTools.Uid()}`,
            status: this.encounterStatus,
            resourceType: "Encounter",
            period: {
                start: moment(this.admissionDate).toJSON()
            },
            subject: {
                reference: `Patient/${this.model.id}`
            },
            identifier: [
                /*{
                    system: 'http://nursit-institute.com/information/patient/created-manually-by',
                    id: UserService.UserName,
                    value: `${UserService.UserLastName}, ${UserService.UserFirstName}`,
                    use: 'official'
                } */
            ],
            location: [
                {location: {reference: `Location/${this.wardId}`}}
            ],
            hospitalization: {
                admitSource: {
                    text: UserService.Practitioner ? UserService.Practitioner.id : UserService.UserName
                }
            },
            class : {
                "system": "http://hl7.org/fhir/v3/ActCode",
                "code": "IMP",
                "display": "Inpatient"
            }
        };

        if (this.roomId && this.roomId !== '-') {
            enc.location.push({location: {reference: `Location/${this.roomId}`}});
        }

        if (this.bedId && this.bedId !== '-') {
            enc.location.push({location: {reference: `Location/${this.bedId}`}});
        }

        let ci = String(this.caseId).trim();
        if (ci && ci != "undefined") {
            enc.identifier.push({
                system: PatientItem.GetVisitNumberSystem(),
                value: ci,
                use: 'official',
                type: {
                    coding: [
                        {
                            code: 'VN',
                            system: 'http://hl7.org/fhir/v2/0203',
                            display: ci
                        }
                    ]
                }
            });
        }

        //#region check if the patient with this number already exists:
        // if (this.patientExists === false) resources.push(this.model);
        //#endregion

        try {
            if (this.patientExists === false) {
                const p = await Fhir.Rest.Create(this.model);
                enc.subject.reference = `Patient/${p.id}`;
            }

            let encEntry = await Fhir.Rest.Create(enc);
            if (encEntry) {
                this.router.navigateToRoute('encounter', {id: encEntry.id});
            }

            await this.controller.ok();
        } catch (error) {
            console.warn(error.message || JSON.stringify(error));
            RuntimeInfo.ShowInfo(`<b>${this.i18n.tr('error_creating_patient')}</b><br>${JSON.stringify(error)}`);
        }
    }

    /**
     * Aborts the currently displayed add-dialogue
     */
    cancel() {
        RuntimeInfo.HideInfo();
        this.controller.cancel();
    }

    /**
     * Submits the currently displayed add-dialogue and tries to create the resources on the server
     */
    async submit() {
        RuntimeInfo.HideInfo();

        window.setTimeout(async () => {
            await this.validationController.validate()
                .then(result => {
                    if (!result.valid) {
                        let div = document.createElement('div');
                        let b = document.createElement('b');
                        b.innerText = this.i18n.tr('validation_error_creating_patient');
                        b.style.display = 'block';

                        let ul: HTMLUListElement = document.createElement('ul');
                        ul.style.display = 'block';
                        ul.style.listStyle = 'decimal';

                        result.results.filter(o => !o.valid).forEach(error => {
                            let li = document.createElement('li');

                            let span = document.createElement('span');
                            span.innerText = error.message;

                            li.appendChild(span);

                            ul.appendChild(li);
                        });

                        div.appendChild(b);
                        div.appendChild(ul);
                        RuntimeInfo.ShowInfo(div.outerHTML, false);
                    } else {
                        this.createResources();
                    }
                })
                .catch(error => {
                    console.warn(error);
                });
        }, 50);
    }

    /**
     * is called when the user selects a different room. 
     * Should load all beds in the given room with the provided id
     */
    async roomIdChanged(newId) {
        if (!newId || newId === '-') return;
        this.beds = [];
        this.bedId = '-';
        this.loadingBeds = true;
        this.beds = await this.loadLocationChildren(newId);
        this.loadingBeds = false;
    }


    /**
     * is called when the user selects a different ward. 
     * Should load all rooms in the given ward with the provided id
     */
    async wardIdChanged(newId) {
        if (newId === '-') return;

        try {
            this.beds = [];
            this.bedId = '-';

            this.loadingRooms = true;
            this.roomId = '-';
            this.rooms = [];

            this.rooms = await this.loadLocationChildren(newId);
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
            this.rooms = [];
        } finally {
            this.loadingRooms = false;
        }
    }

    async attached() {
        this.wardId = RuntimeInfo.CurrentWardId;
        this.validationController.reset(this.rulez);
        this.patientNameRestrictions = RuntimeInfo.Features.patientNameRestrictions;
    }

    /**
     * Generates a new instance of the add dialogue class
     * @param router the approuter to use - handled by autoinjection
     * @param app the current app instance - handled by autoinjection
     * @param locationService the LocationService to use - handled by autoinjection
     * @param controller the DialogueController to use - handled by autoinjection
     * @param service the DialogService to use - handled by autoinjection
     * @param i18n the I18N to use - handled by autoinjection
     * @param validationControllerFactory the ControllerFactory to use - handled by autoinjection
     * @param validator the Validator instance to use - handled by autoinjection
     */
    constructor(router: Router, app: App, locationService: LocationService, controller: DialogController, service: DialogService, i18n: I18N, validationControllerFactory: ValidationControllerFactory, validator: Validator) {
        if (ConfigService.Debug) window["patientAdd"] = this;
        this.router = router;
        this.app = app;
        this.locationService = locationService;
        this.controller = controller;
        this.service = service;
        this.i18n = i18n;
        this.controller.settings.centerHorizontalOnly = true;
        this.isMobile = RuntimeInfo.IsMobile;

        this.validator = validator;
        this.validationController = validationControllerFactory.createForCurrentScope(this.validator);
        this.rulez = ValidationRules
            .ensure('firstName').required().withMessage(this.i18n.tr('validation_error_first_name'))
            .ensure('lastName').required().withMessage(this.i18n.tr('validation_error_last_name'))
            .ensure('gender').satisfies(g => g && g !== '-').withMessage(this.i18n.tr('validation_error_gender'))
            .ensure('wardId').satisfies(w => w && w !== '-').withMessage(this.i18n.tr('validation_error_ward'))
            // .ensure('roomId').satisfies(r => r && r !== '-').withMessage(this.i18n.tr('validation_error_room'))
            // .ensure('bedId').satisfies(b => b && b !== '-').withMessage(this.i18n.tr('validation_error_bed'))
            .ensure('admissionDate').required().withMessage(this.i18n.tr('validation_error_admission_date'))
            .ensure('caseId').required().withMessage(this.i18n.tr('validation_error_case_id'))
            .ensure('born').required().withMessage(this.i18n.tr('validation_error_born'))
            .rules;

        this.validationController.addObject(this, this.rulez);

        this.wards = [];
        this.wards.push({id: '-', name: this.i18n.tr('select')});
        this.locationService.wards.filter(o => o.status === 'active').forEach(wd => {
            if (UserService.Role && UserService.Role.location && UserService.Role.location.length > 0) {
                if (UserService.Role.location.find(o => o && o.reference && NitTools.GetId(o.reference) === wd.id)) {
                    this.wards.push({id: wd.id, name: wd.name});
                }
            } else {
                this.wards.push({id: wd.id, name: wd.name});
            }
        });

        let a: Date = new Date();
        let d = a.getDate() - 1;
        let m = a.getMonth();
        let y = a.getFullYear();

        this.admissionDate = new Date(y, m, d);


        this.model = {
            birthDate: moment(new Date(y - 18, m, 1)).toJSON().split('T')[0],
            gender: 'unknown',
            active: true,
            resourceType: "Patient",
            name: [
                {
                    family: '',
                    given: ['']
                }
            ]
        };

        this.validationController.addRenderer(new BootstrapFormRenderer());

        if (RuntimeInfo.CurrentWardId) {
            window.setTimeout(async () => {
                await this.wardIdChanged(RuntimeInfo.CurrentWardId);
                // this.wardId = RuntimeInfo.CurrentWardId;
            }, 200);
        }

        /* ValidationRules
            .ensure('firstName').required()
            .ensure('lastName').required()
            .on(this); */
    }
}
