import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {translations} from "../../resources/classes/translations";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import {ConfigService} from "../../resources/services/ConfigService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {NitTools} from "../../resources/classes/NursitTools";
import {LocationService} from "../../resources/services/LocationService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";

const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {QuestionnaireResponse} from "../../resources/classes/FhirModules/Fhir";
import SystemHeaders from "../../resources/classes/SystemHeaders";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";

export class PatientDischarge extends FormBaseClass {
    questionnaireName: string = "CareITDischargeManagement";
    questionnaireList: IQuestionnaireList = undefined;
    showSaveButton = PatientDischarge.Recare.enabled;
    pageTitle = translations.translate("release_management");
    locationService: LocationService;

    public static Recare = {
        "enabled": false,
        "senderId": 1,
        "applicationUrl": "https://app-preprod.recaresolutions.com",
        "apiUrl": "https://api-preprod.recaresolutions.com/kis/patient?token=C5rE8nPdYtSQu0UQxZU_oePcZ_2LLJnMobfCPD6VhSkyOuI=",
        "wards": []
    };

    async attached() {
        this.locationService = new LocationService();
        this.route = ConfigService.FormNames.Discharge;
        await super.attached();
        // await this.mapValues();

        this.showResponseSelect = false;

        RuntimeInfo.IsLoading = false;

        if (ConfigService.Debug)
            window["discharge"] = this;
    }

    writeLatestLinkToFlags() {
        if (!this.patient || !this.patient.flags || !this.response || !this.questionnaire) return;

        let systemEnd = `latest${this.questionnaire.name}Date`;
        let coding = this.patient.flags.code.coding.find(o => o.system.endsWith(systemEnd));
        if (!coding) {
            coding = {
                system: NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/' + systemEnd
            };

            this.patient.flags.code.coding.push(coding);
        }

        if (coding && !this.response) { // don't forget to remove the coding, if the qr does not exist. May happen when the response has been stopped
            this.patient.flags.code.coding.splice(this.patient.flags.code.coding.indexOf(coding), 1);
        } else {  // if coding is there and a qr, update the coding
            coding.code = new Date(this.response.authored).toJSON();
        }
    }

    // async mapValues() {
    //     if (!this.response || !this.patient || !this.patient.flags || this.response.status!=='in-progress') return;

    //     const qList = await QuestionnaireService.GetQuestionnaireIds();
    //     let bi: number = undefined;
    //     let spi: string = '';
    //     await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

    //     const assessment = this.patient && this.patient.latestAssessment ? this.patient.latestAssessment : this.questionnaireService.getLatestResponseOfType(this.patient, qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

    //     if (assessment) {
    //         const clFlag = this.patient.flags.code.coding.find(o => o.system.endsWith('/CareLevelString'));

    //         if (typeof clFlag !== "undefined") {
    //             spi = clFlag.code;

    //             const spiFlag = this.patient.flags.code.coding.find(o => o.system.endsWith('/SPI'));
    //             if (typeof spiFlag !== "undefined") {
    //                 spi += ` (${spiFlag.code})`;
    //             }
    //         }
    //     }

    //     let barthelResponse = await Fhir.QuestionnaireResponse.SeekForAttachedResponse(this.patient, 'barthelindex', 'BarthelIndex');
    //     if (barthelResponse) {
    //         let barthelItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(barthelResponse, "BI_11", false);
    //         if (!barthelItem)
    //             barthelItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(barthelResponse, "NIT_BI_10", false);

    //         if (barthelItem)
    //             bi = Fhir.QuestionnaireResponse.GetResponseItemValueInt(barthelItem, undefined);
    //     }

    //     let update = false;
    //     let response = QuestionnaireService.GetLatestResponseOfType(this.patient, qList.QDischargeManagementId, []);
    //     if (response) {
    //         let spiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_3", true);
    //         if (spiItem) {
    //             const curVal = QuestionnaireResponse.GetResponseItemValue(spiItem);
    //             if (typeof curVal !== "string" || String(curVal) !== spi) {
    //                 spiItem.answer = [{valueString: spi}];
    //                 update = true;
    //             }
    //         }

    //         let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, "E_4", true);
    //         if (biItem && bi) {
    //             const curVal =QuestionnaireResponse.GetResponseItemValue(biItem);
    //             if (typeof  curVal != "string" || String(curVal) !== String(bi)) {
    //                 biItem.answer = [{valueString: String(bi)}];
    //                 update = true;
    //             }
    //         }

    //         const dischargeSetting = ConfigService.GetFormSettings(this.route);
    //         if (dischargeSetting && dischargeSetting.settings && dischargeSetting.settings["dischargeActiveWhen"]) {
    //             const [field, value] = dischargeSetting.settings["dischargeActiveWhen"].split('=');

    //             const anamnesis = QuestionnaireService.GetLatestResponseOfType(this.patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
    //             if (anamnesis) {
    //                 const dischargeItemValue = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, field, '');
    //                 let isActive = dischargeItemValue === value;

    //                 const E2 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, 'E_2', false);
    //                 if (E2) {
    //                     const curVal = QuestionnaireResponse.GetResponseItemValue(E2);
    //                     if (typeof curVal != "boolean" || NitTools.ParseBool(curVal) != isActive) {
    //                         E2.answer = [{valueBoolean: isActive}];
    //                         update = true;
    //                     }
    //                 }
    //             }
    //         }

    //         if (update) {
    //             PatientService.AddQuestionnaireResponse(this.patient, response, true);
    //         }
    //     }
    // }

    sendToRecare() {
        if (!this.patient) {
            console.warn("No current patient to send to recare. exiting");
            return false;
        }

        if (this.patient.recareUrl || "" !== "") {
            window.open(this.patient.recareUrl, "_recare", "toolbar=no, statusbar=no");
            return;
        }

        let stationName: string = undefined;
        let encounter = this.patient.encounter;
        if (encounter) {
            let locations = [];
            encounter.location.forEach(encLoc => {
                let locationId = encLoc.location.reference;
                if (locationId.indexOf("/") > -1) {
                    locationId = locationId.split("/")[1];
                }

                let appLoc = this.locationService.getLocationById(locationId);

                if (appLoc) {
                    locations.push(appLoc);
                }
            });

            let wards = locations.filter(o => o.physicalType && o.physicalType.coding && o.physicalType.coding.length > 0 && o.physicalType.coding[0].code === "wa");
            let ward = undefined;
            if (wards.length > 0) {
                ward = wards[0];
            }

            if (!ward) {
                console.warn("No ward found in ", this.patient.encounter.location);
                stationName = "unnamed";
            } else {
                stationName = ward.name;
            }

            // do the ward mapping from fhir to recare:

            for (let i = 0; i < PatientDischarge.Recare.wards.length; i++) {
                let wd = PatientDischarge.Recare.wards[i];
                if (wd.local.toUpperCase() === stationName.toUpperCase() && wd.recare) {
                    stationName = wd.recare;
                    if (ConfigService.Debug) {
                        console.debug("[RecareMapping] - Mapped ward from '" + wd.local + "' to: " + stationName);
                    }
                }
            }
        }

        let eintritt: number = 0;
        let austritt: number = 0;
        let patientLanguage = "Deutsch";

        this.fhirService.get(`Encounter/${this.patient.encounterId}`)
            .then((result: any) => {
                if (!result.identifier) {
                    result.identifier = [{
                        system: PatientItem.GetVisitNumberSystem(),
                        use: "official",
                        value: this.patient.encounter.id
                    }];
                }

// let encounterIdentifier = patient.encounter.identifier && patient.encounter.identifier[0] ? ' (' + patient.encounter.identifier[0].value + ')' : '';
                let encounterIdentifier;
                if (result && result.identifier) {
                    let ident: any = result.identifier.find(o => o.system?.indexOf('/visitNumber') > -1);
                    if (!ident) ident = result.identifier.find(o => o.system.endsWith('/sourceId'));
                    if (ident) encounterIdentifier = ident;
                }

                /* let encounterIdentifier = result.identifier.find(o=>o.use === "official");
                if (!encounterIdentifier)  encounterIdentifier = result.identifier.find(o=>o.use === "secondary");
                if (!encounterIdentifier) encounterIdentifier = result.identifier[0]; */

                if (this.patient.encounter.period) {
                    if (this.patient.encounter.period.start) {
                        eintritt = moment(this.patient.encounter.period.start).unix();
                    }
                }

                let releaseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, "E_16", false);
                if (releaseItem) {
                    let releaseVal = Fhir.QuestionnaireResponse.GetResponseItemValue(releaseItem);
                    if (releaseVal) {
                        austritt = moment(releaseVal).unix();
                    }
                }

                let speaksGerman = true; // 00_03
                let anamnesen = this.patient.questionnaireResponses.filter(o => o.questionnaire.reference.indexOf("Questionnaire/" + this.questionnaireList.QAnamnesisId) > -1);
                if (anamnesen.length > 0) {
                    let anamnese = anamnesen[0];

                    let dolmetscherItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnese, "00_03", false);
                    if (dolmetscherItem) {
                        let answerValue = Fhir.QuestionnaireResponse.GetResponseItemValueInt(dolmetscherItem);
                        // 0= no answer so indicate yes, 1 = no translator needed, 2 = translator needed
                        if (answerValue === 2) {
                            speaksGerman = false;
                        }
                    }

                    if (!speaksGerman && anamnese) {
                        let sprachItem: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnese, "99_53");
                        if (sprachItem && sprachItem.text) {
                            patientLanguage = sprachItem.text;
                        }
                    }
                }

                /////////
                let dummy = {
                    "station": stationName,
                    "profile": {
                        "gender": this.patient.gender === "male" ? 1 : 2,
                        "admission_date_in_hospital": eintritt,
                        "expected_discharge_date": austritt,
                        "communication": {
                            "patient_cannot_communicate_in_german": !speaksGerman,
                            /// die nehmen IDs, das funktioniert so nicht: "patient_language": patientLanguage
                        }
                    },
                    /*  die benutzen anderen diagnosen, aufnahmediagnose ist nicht die die interessiert:
                    "diagnosis": {
                        "main_diagnosis": diagnose || ""
                    }, */
                    /* unbekannt, was dieses Feld überhaupt aussagens soll: "care_needs": {
                        "companionship": "yes"
                    }, */
                    "case_id": encounterIdentifier ? encounterIdentifier.value : '', // this.patient.encounterId,
                    "sender_id": PatientDischarge.Recare.senderId,
                };

                if (eintritt === 0) // eintritt is invalid, so delete it
                    delete dummy.profile.admission_date_in_hospital;

                if (austritt === 0)
                    delete dummy.profile.expected_discharge_date;

                let data = JSON.stringify(dummy);

                // noinspection JSIgnoredPromiseFromCall
                $.ajax({
                    url: PatientDischarge.Recare.apiUrl,
                    method: "POST",
                    data: data,
                    dataType: "json",
                    contentType: "application/json",
                    success: (result) => {
                        this.patient.recareUrl = result["patient_profile_link"];
                        if (this.patient.selectedAdditionalInfo) {
                            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "patient_profile_link", true);
                            if (item) {
                                item.answer = [{valueString: this.patient.recareUrl}];

                                this.fhirService.update(this.patient.selectedAdditionalInfo)
                                    .then(() => {
                                        this.buttonText = "open_in_recare";
                                    })
                                    .catch(error => {
                                        this.patient.recareUrl = undefined;
                                        console.warn(error);
                                    });
                            }
                        } else {
                            console.warn("no additionInfo for current patient");
                        }
                    },
                    error: (error => {
                        console.warn("error", error);
                    })
                });
            })
            .catch(error => {
                console.warn(error);
            });

    }

    afterLoadedData() {
        super.afterLoadedData();
        if (!this.patient) return;

        this.questionnaireList = this.qList;

        this.patientService.ensureQuestionnaireExistance(this.patient, this.questionnaireList.QDischargeManagementId, `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`, fhirEnums.QuestionnaireResponseStatus.inProgress)
            .then(() => {
                if (!this.response) {
                    this.response = QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaireList.QDischargeManagementId, []);
                }

                if (PatientDischarge.Recare.enabled) {
                    if (this.patient.recareUrl || "" !== "") {
                        this.buttonText = "open_in_recare";
                    } else {
                        this.buttonText = "send_to_recare";
                    }
                } else {
                    this.buttonText = '';
                }
            });

    }

    async saveButtonStateChanged(sender): Promise<void> {
        await super.saveButtonStateChanged(sender);
        this.tooOld = false;
        //this.readonly = false;
        this.showSaveButton = true;
        this.useQuestionnaireStatusForSave = false;
        // let oldState = sender.buttonState;
        sender.buttonState = "edit";
        sender.isEditable = true;
        sender.isTooOld = false;
        sender.simpleButton = true;

        this.readonly = false; //oldState !== "save";
    }

    override async createButtonClicked() : Promise<any> {
        // don't dare to call super.createButtonClicked() !!! create is the send to recare button!!!
        return new Promise<any>((resolve, reject) => {
            if (this.response) {
                this.fhirService.update(this.response)
                    .then(() => {
                        this.sendToRecare();
                        resolve(this.response);
                    })
                    .catch(error => {
                        console.warn("When updating QR:", error);
                        reject(error);
                    });
            } else {
                reject('We have no response!');
            }
        });
    }

    async afterSave(response: any) {
        await super.afterSave(response);


        this.router.navigateBack();
    }

    async beforeSave() {
        this.writeLatestLinkToFlags();
        Fhir.Tools.UpdateAuthor(this.response);
        this.patientService.updatePatientDischargePct(this.patient);


        if (this.patient?.selectedAdditionalInfo) {
            let percentItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "dischargePercent", true);
            let colorItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "dischargeColor", true);

            if (percentItem)
                percentItem.answer = [{valueInteger: this.patient.dischargePercent}];

            if (colorItem)
                colorItem.answer = [{valueString: this.patient.dischargeColor}];
        }

        await super.beforeSave();
    }

    afterCreate(result: any) {
        super.afterCreate(result); // selects the new response too

        if (this.response.id !== result.id) {
            PatientService.AddQuestionnaireResponse(this.patient, result);
            this.response = result;
        }
    }
}
