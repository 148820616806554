import {AnalyzeService} from '../../../resources/services/analyzeService';
import {translations} from "resources/classes/translations";
import {Reason} from "../../../resources/classes/reasonList";
import {IQuestionnaireDialogSettings, QuestionnaireDialogContainer} from "../../../resources/elements/questionnaire/questionnaire-dialog-container";
import {DialogService} from 'aurelia-dialog';
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {QuestionnaireResponse, Tools} from "../../../resources/classes/FhirModules/Fhir";
import {PKMS} from "../../../resources/classes/IPKMSItem";
import {ICDCodeItem} from "../../../resources/classes/IICDCode";
import {NitTools} from "../../../resources/classes/NursitTools";
import {I18N} from "aurelia-i18n";
import {FhirService} from "../../../resources/services/FhirService";
import {UserService} from "../../../resources/services/UserService";
import {IFormSetting} from "../../../resources/classes/IFormSettings";
import {inject} from "aurelia-framework";
import {BasicForm} from "../../../resources/elements/BasicForm";
import {ConfigService} from "../../../resources/services/ConfigService";
import {PatientChangeNotifier} from "resources/services/PatientChangeNotifier";
import {ReportService} from "resources/services/ReportService";
import {DialogMessages} from "resources/services/DialogMessages";
import {PKMSService} from "resources/services/PKMSService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {ICDService} from "resources/services/ICDService";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {AnalyzerEpaPSYC} from "../../../resources/services/Analyzers/epaPSYC";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";
import PKMSReasonGroup = PKMS.PKMSReasonGroup;
import HTTPVerb = fhirEnums.HTTPVerb;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

@inject(PatientChangeNotifier, DialogService, I18N, FhirService, ICDService, AnalyzeService, DialogMessages, PatientService)
export class PflegeplanungAnalyse {
    //region Variables
    private __pkmsHtml: string = undefined;
    private _enableICDMapping: boolean;
    private __isAnalysePossible: boolean = false;
    private _biExtText: string = undefined;
    private _biText: string = undefined;

    protected static riskFields;
    protected fhirService: FhirService;
    protected showPrintButton: boolean = false;
    protected dialogMessages: DialogMessages;
    protected anamneseDate: Date = undefined;
    protected assessmentDate: Date = undefined;
    protected pkmsVisible: boolean;
    protected biValidated: boolean = false;
    protected biExValidated: boolean = false;
    protected fimValidated: boolean = false;
    protected formSetting: IFormSetting;
    protected qList: IQuestionnaireList;
    protected formName: string = ConfigService.FormNames.Analysis;
    protected configService: ConfigService;
    protected notifier: PatientChangeNotifier;
    protected showPdfButton: boolean = false;
    protected matchingICDItems: ICDCodeItem[] = [];
    analyzeBody: HTMLDivElement;
    riskCodings: any[] = [];

    protected get patient(): PatientItem {
        return PatientItem.LastLoadedPatient || PatientItem.SelectedPatient;
    }

    protected fimText: string = "0";
    protected validVideoUrl: boolean = false;
    protected patientService: PatientService;
    protected dialogService: DialogService;
    protected dialogSettings: IQuestionnaireDialogSettings = undefined;
    protected currentStatus: string = "unknown";

    protected get encounterId(): string {
        return this.patient?.encounterId;
    }

    protected currentQuestionnaire: any;
    protected currentResponse: any;
    public isLoading: boolean = true;
    protected doSelfSave: boolean = false;
    protected socialServiceUrl: string;
    protected groups: PKMSReasonGroup[];
    protected careLevelColor: string;
    protected showSpiRiskButton: boolean = false;
    protected showVideoButton: boolean = false;
    protected analyzeService: AnalyzeService;
    protected biNotValidatedTitle: string;
    protected biExNotValidatedTitle: string;
    protected fimNotValidatedTitle: string;
    protected biExists: boolean = false;
    protected biExExists: boolean = false;
    protected fimExists: boolean = false;
    protected hasExpertEvaluation: boolean = false;
    protected expertEvaluationText: string = "";
    protected conditions: any[];
    protected icdTexts: string[];
    public icdList: any[];
    protected processingPrint: boolean = false;

    protected getRiskCoding(identifier: string): any {
        if (!this.riskCodings) return undefined;
        let result = this.riskCodings.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash((identifier))));

        return result;
    }

    protected getRiskText(identifier: string): string {
        const risk = this.getRiskCoding(identifier);
        if (risk) {
            if (risk.display) return risk.display;
            else if (risk.code) return this.i18n.tr(risk.code === '0' ? 'false' : 'true');
        }

        return '';
    }

    get pkmsHtml(): string {
        return this.__pkmsHtml;
    }

    set pkmsHtml(value: string) {
        this.__pkmsHtml = value;
    }

    get showICDs(): boolean {
        return this.formSetting.settings["enableICDMapping"];
    }

    get riskFields() {
        return PflegeplanungAnalyse.riskFields;
    }

    get encounter(): any {
        return this.patient.encounter;
    }

    get isAnalysePossible(): boolean {
        return this.__isAnalysePossible;
    }

    set isAnalysePossible(val: boolean) {
        this.__isAnalysePossible = val;
    }

    /*get showFim() {
        if (typeof this._showFim === "undefined") {
            let formSettings = ConfigService.GetFormSettings('fim');
            this._showFim = formSettings.enabled;
        }

        return this._showFim;
    } */

    get careLevelText(): string {
        let s = this.i18n.tr("analyse_info_text");
        let careLevelItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "CareLevel", false);
        let spiItemText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "risk_spi_sum_text", false);
        let spiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "risk_spi_sum", false);

        if (!careLevelItem || !spiItem) return "";
        let valCL = Fhir.QuestionnaireResponse.GetResponseItemValue(spiItemText);
        let valSpi = Fhir.QuestionnaireResponse.GetResponseItemValue(spiItem);

        s = s.replace(/%CARELEVEL%/gi, valCL).replace(/%SPI%/gi, valSpi);

        // check first if the color differs to avoid domm updates
        let col = Fhir.Tools.CareLevelToTextColor(Fhir.Tools.SpiToCareLevel(valSpi));
        if (col !== this.careLevelColor) this.careLevelColor = col;

        return s;
    }

    get spiRiskText(): string {
        let spiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "risk_spi", false);
        let boolValue = NitTools.ParseBool(Fhir.QuestionnaireResponse.GetResponseItemValue(spiItem));

        this.showSpiRiskButton = this.socialServiceUrl && boolValue;
        // let spiText = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "risk_spi_risk_text", false);
        return this.i18n.tr(boolValue ? "yes" : "no");

        // Fhir.QuestionnaireResponse.GetResponseItemValue(spiText);
    }

    get videoUrl() {
        if (!this.validVideoUrl) return undefined;
        return this.formSetting.settings["videoUrl"];
    }

    get decubitusText(): string {
        if (this.validVideoUrl) {
            let decuItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "risk_deku", false);
            let value = NitTools.ParseBool(Fhir.QuestionnaireResponse.GetResponseItemValue(decuItem));
            this.showVideoButton = this.validVideoUrl && value;
        }

        return this.getRiskText('risk_deku');
    }

    get pneumoText(): string {
        return this.getRiskText('risk_pneu');
    }

    get showStammBlatt(): boolean {
        return this.formSetting.settings["showStammblatt"];
    }

    get fallText(): string {
        return this.getRiskText('risk_sturz');
    }

    get vddText(): string {
        return this.getRiskText('risk_vdd');
    }

    get continenceText(): string {
        return this.getRiskText('kontinenz');
    }

    get nrsText(): string {
        return this.getRiskText('risk_nrs_sum');
    }

    get biText(): string {
        return this.getRiskText('BarthelIndex');
    }

    get biExText(): string {
        return this.getRiskText('BarthelIndexEx');
        /*
        if (!this._biExtText) {
            let ebiItem; // = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "EBI_07", false);

            QuestionnaireResponse.SeekForAttachedResponse(this.patient, 'barthelindexEx', 'BarthelIndexEx')
                .then(attached => {
                    if (attached) {
                        ebiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(attached, "EBI_07", false);
                    }

                    this._biExtText = Fhir.QuestionnaireResponse.GetResponseItemValue(ebiItem);
                })
        }

        return this._biExtText || ''; */
    }

    get pkmsTexts(): string[] {
        let pkmsItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "PKMS_Diagnosis", false);
        if (!pkmsItem || !pkmsItem.answer) return [];

        let texts = [];
        pkmsItem.answer.filter(answer => answer.valueCoding && answer.valueCoding.display).forEach(answer => {
            texts.push(answer.valueCoding.display);
        });

        if (texts.length === 0) {
            texts.push('-');
        }

        return texts;
    }

    //endregion

    constructor(notifier: PatientChangeNotifier, dialogService: DialogService,
                protected i18n: I18N,
                fhirService: FhirService,
                protected icdService: ICDService,
                analyzeService: AnalyzeService, dialogMessages: DialogMessages, patientService: PatientService,
                protected userService: UserService) {
        this.isLoading = true;
        this.notifier = notifier;
        this.fhirService = fhirService;
        this.dialogService = dialogService;
        this.analyzeService = analyzeService;
        this.dialogMessages = dialogMessages;
        this.patientService = patientService;
    }

    protected async reloadQuestionnaires(encounterId: string) {
        // PatientItem.SelectedPatient = PatientItem.LastLoadedPatient = await PatientItem.Load(encounterId, true);
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

        const qAss = await QuestionnaireService.GetQuestionnaireByName(this.patient.getAssessmentName());
        const qAna = await QuestionnaireService.GetQuestionnaireByName(this.patient.getAnamnesisName());
        let url = `QuestionnaireResponse?${FhirService.FhirVersion >= 4 ? 'patient' : 'subject'}=${this.patient?.id}`+
                         `&${FhirService.FhirVersion >= 4 ? 'encounter' : 'context'}=${encounterId}` +
                         `&questionnaire=${QuestionnaireService.GetQuestionnaireQueryUrl(qAna, qAss)}` +
                         `&status=completed,amended,in-progress`;

        const responses = await this.fhirService.fetch(url);

        for (const response of responses) {
            PatientService.AddQuestionnaireResponse(this.patient, response, true);
        }

        // this.patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, qAss.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        // console.debug('Latest Assessment:', this.patient.latestAssessment);
    }

    private _isPSYC: boolean = undefined;
    public get isPSYC(): boolean {
        if (typeof this._isPSYC === 'undefined') {
            if (this.patient && this.patient.latestAssessment) {
                const ext = Fhir.Tools.GetOrCreateExtension(this.patient.latestAssessment, '/AnalyzerVersion', false);
                const val: string = Fhir.Tools.GetExtensionValue(ext);
                if (val) {
                    this._isPSYC = val.toUpperCase().indexOf('EPAPSYC') > -1;
                }
            }
        }

        return this._isPSYC;
    }

    async bind() {
        RuntimeInfo.IsLoading = false;
    }

    private _hasCustomHtml = false;
    get hasCustomHtml(): boolean {
        if (!this._hasCustomHtml)
            this._hasCustomHtml = !!(this.analyzeService && this.analyzeService.analyzer && this.analyzeService.analyzer.resultHtml);

        return this._hasCustomHtml;
    }

    get customHtml(): string {
        return this.hasCustomHtml ? this.analyzeService.analyzer.resultHtml : '';
    }

    showPatientWarning : boolean = false;
    protected async attached(params) {
        if (ConfigService.Debug) window["analyse"] = this;

        try {
            await PatientItem.waitForLoading();

            if (!this.patient && typeof RuntimeInfo.SelectedEncounter === "string") {
                await PatientItem.Load(RuntimeInfo.SelectedEncounter);
            }

            if (!this.patient) {
                this.showPatientWarning = true;
                return;
            }

            document.body.classList.add("no-toolbar-window");
            await PatientItem.Load(this.encounterId);

            await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            this.formSetting = ConfigService.GetFormSettings(this.formName);
            await this.reloadQuestionnaires(this.patient.encounterId);

            if (this.formSetting)
                BasicForm.pageTitle = this.i18n.tr(this.formSetting.title);

            this.showPdfButton = RuntimeInfo.Features.showFastPdfPrintButton;
            this.validVideoUrl = !!this.formSetting.settings["videoUrl"];
            this._enableICDMapping = this.formSetting.settings["enableICDMapping"];
            this.showPrintButton = !!ReportService.ReportServer;

            if (!ConfigService.IsTest) {
                this.i18n.i18next.loadResources();
            }

            await this.startUp();
            await this.loadPKMSReasons();

            if (!this.patient.assessmentName) {
                const settingAssessment = ConfigService.GetFormSettings('assessment');
                this.patient.assessmentName = settingAssessment.questionnaireName;
            }

            if (typeof this.patient.latestAssessment === 'undefined') {
                const q = QuestionnaireService.GetQuestionnaireByNameDirect(this.patient.assessmentName);
                this.patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, q.id);
            }

            await this.checkIfBIAndBiExAreValidated();
            this.conditions = [];

            let icds = await this.icdService.calculateICDCodes(this.patient, this.patient.latestAssessment);

            // when the expert has evaluated the decu risk, display that information
            // 02_03 = Assessment."Experteneinschätzung Dekubitusrisiko"
            // value 02_03_1 is "YES"
            if (this.patient.latestAssessment) {
                let expertEvalItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, "02_03", false);
                let expertEvalValue = Fhir.QuestionnaireResponse.GetResponseItemValue(expertEvalItem);
                this.hasExpertEvaluation = expertEvalValue === "02_03_01";

                if (this.hasExpertEvaluation) {
                    let questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(this.patient?.latestAssessment?.questionnaire);
                    if (questionnaireAssessment) {
                        let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, "02_03");
                        if (questionnaireItem) {
                            this.expertEvaluationText = questionnaireItem.text;
                        }
                    }
                }

                this.conditions = await this.icdService.fetch(this.patient.latestAssessment.id);
            }

            this.icdList = icds; // .map(o=>{id: o.icd, text: o.text}); // this.getIcdTexts();

            if (this.isPSYC) {
                await this.analyzeService.analyse(this.patient, this.patient.latestAssessment, false, false);
                this.analyzerEpaPSYC = <AnalyzerEpaPSYC>this.analyzeService.analyzer;
            }

            if (this.patient && this.patient.currentRisks && this.patient.currentRisks.prediction) {
                this.riskCodings = this.patient.currentRisks.prediction.filter(o => o.outcome).map(o => o.outcome).filter(o => o.coding).map(o => o.coding).filter(o => o.length >= 1).map(o => o[0]);
            }
        } catch (e) {
            console.warn(e);
        } finally {
            this.isLoading = false;
        }
    }

    analyzerEpaPSYC: AnalyzerEpaPSYC = undefined;

    get suicidality(): string {
        if (!this.analyzerEpaPSYC) return '';
        return this.i18n.tr(this.analyzerEpaPSYC.suicidality.isRisk ? 'yes' : 'keine RI');
    }

    get violence(): string {
        if (!this.analyzerEpaPSYC) return '';
        return this.i18n.tr(this.analyzerEpaPSYC.violence.isRisk ? 'yes' : 'keine RI');
    }

    get sfi(): string {
        if (!this.analyzerEpaPSYC) return '';
        return String(this.analyzerEpaPSYC.SFI);
    }

    get spi(): string {
        if (!this.analyzerEpaPSYC) return '';
        return String(this.analyzerEpaPSYC.SPI);
    }

    get expertRiskDecu() {
        if (!this.analyzerEpaPSYC) return undefined;
        return this.analyzerEpaPSYC.expertRiskDecu;
    }

    get braden() {
        if (!this.analyzerEpaPSYC) return undefined;
        return this.analyzerEpaPSYC.braden;
    }

    protected detached() {
        this.doSelfSave = false;
        document.body.classList.remove("no-toolbar-window");
    }

    protected async showAnalyzerInfo() {
        let v = await this.analyzeService.getAnalyzerVersion(this.patient, this.patient.latestAssessment);
        let msg = `Verwendeter Analyzer: "${v}"`;
        this.dialogMessages.prompt(msg, this.i18n.tr('information'), false);
    }

    private async checkIfBIAndBiExAreValidated(isRepeatedCheck: boolean = false) {
        if (!this.patient || !this.patient.latestAssessment) return;
        let defaultTitle = this.i18n.tr("document");

        let bi: any = await QuestionnaireResponse.SeekForAttachedResponse(this.patient, 'BARTHELINDEX', 'BarthelIndex');
        let biEx: any = await QuestionnaireResponse.SeekForAttachedResponse(this.patient, 'BARTHELINDEXEX', 'BarthelIndexEx');
        let fim: any = await QuestionnaireResponse.SeekForAttachedResponse(this.patient, 'FIM', 'FIM');

        let createBundle : any[] = [];
        // noinspection DuplicatedCode
        if (bi) {
            this.biExists = true;
            let q = QuestionnaireService.GetQuestionnaireDirect(bi?.questionnaire);
            this.biNotValidatedTitle = this.i18n.tr("not_validated").replace("%QUESTIONNAIRETITLE%", (q && q.title ? q.title : defaultTitle));
            this.biValidated = bi.status === fhirEnums.QuestionnaireResponseStatus.amended || bi.status === fhirEnums.QuestionnaireResponseStatus.completed;
        } else {
            // console.debug(" BarthelIndex added for creation")
            let _doc = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.qList.QBarthelIndexId, fhirEnums.QuestionnaireResponseStatus.inProgress);
            _doc.id = NitTools.Uid();
            QuestionnaireResponse.SetAttachedResponse(this.patient, this.patient.latestAssessment, _doc);
            QuestionnaireResponse.SetAttachedResponse(this.patient, _doc, this.patient.latestAssessment);
            createBundle.push(_doc);
        }

        // noinspection DuplicatedCode
        if (biEx) {
            this.biExExists = true;
            let q = QuestionnaireService.GetQuestionnaireDirect(biEx?.questionnaire);
            this.biExNotValidatedTitle = this.i18n.tr("not_validated").replace("%QUESTIONNAIRETITLE%", (q && q.title ? q.title : defaultTitle));

            this.biExValidated = biEx.status === fhirEnums.QuestionnaireResponseStatus.amended || biEx.status === fhirEnums.QuestionnaireResponseStatus.completed;
        } else {
            let _doc = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.qList.QBarthelIndexExId, fhirEnums.QuestionnaireResponseStatus.inProgress);
            _doc.id = NitTools.Uid();
            QuestionnaireResponse.SetAttachedResponse(this.patient, this.patient.latestAssessment, _doc);
            QuestionnaireResponse.SetAttachedResponse(this.patient, _doc, this.patient.latestAssessment);
            createBundle.push(_doc);
        }

        // noinspection DuplicatedCode
        const fimForm : IFormSetting = ConfigService.GetFormSettings('FIM');
        // only try to create FIM if it is enabled (since 2019 always disabled)
        if (fimForm && fimForm.enabled) {
            if (fim) {
                this.fimExists = true;
                let q = QuestionnaireService.GetQuestionnaireDirect(fim?.questionnaire);
                this.fimNotValidatedTitle = this.i18n.tr("not_validated").replace("%QUESTIONNAIRETITLE%", (q && q.title ? q.title : defaultTitle));

                this.fimValidated = fim.status === fhirEnums.QuestionnaireResponseStatus.amended || fim.status === fhirEnums.QuestionnaireResponseStatus.completed;
            } else {
                let _doc = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.qList.QFimId, fhirEnums.QuestionnaireResponseStatus.inProgress);
                _doc.id = NitTools.Uid();
                QuestionnaireResponse.SetAttachedResponse(this.patient, this.patient.latestAssessment, _doc);
                QuestionnaireResponse.SetAttachedResponse(this.patient, _doc, this.patient.latestAssessment);
                createBundle.push(_doc);
                // console.debug("FIM added for creation")
            }
        }

        // do not perform this when it is the second check or nothing to update
        if (this.isAnalysePossible && !isRepeatedCheck && createBundle.length > 0) {
            // console.debug("Creating missing bundle", createBundle);
            RuntimeInfo.HideInfo();
            if (typeof createBundle.find(o=>o.id === this.patient.latestAssessment.id) === "undefined") {
                createBundle.push(this.patient.latestAssessment);
            }

            RuntimeInfo.ShowInfo(this.i18n.tr("analyse_missing_document_generating"), false);
            this.fhirService.bundle(createBundle, HTTPVerb.put)
                .then(async result => {
                    //     console.debug("Creation returned:", result);

                    let _r = <any[]>result.entry.filter(o => o && o.resource && o.resource.resourceType === "QuestionnaireResponse" && o.resource).map(entry => {
                        return entry.resource;
                    });

                    _r.forEach(response => {
                        PatientService.AddQuestionnaireResponse(this.patient, response);
                    });

                    await this.analyzeService.analyse(this.patient, this.patient.latestAssessment);

                    // .. and update ids
                    if (!this.patient.isOffline) {
                        this.fhirService.update(this.patient.latestAssessment).then(async () => {
                            RuntimeInfo.HideInfo();
                            RuntimeInfo.ShowInfo(this.i18n.tr("analyse_missing_document_generating_finished"));
                            await this.checkIfBIAndBiExAreValidated(true);
                        });
                    }
                });
        }
    }

    protected getIcdTexts(): string[] {
        let result = [];
        this.icdList = [];

        // if (ConfigService.Debug) console.debug("Getting ICDs from Conditions");

        this.conditions.filter(o => o.clinicalStatus === 'active' || o.clinicalStatus === 'recurrence').forEach((condition: any) => {
            if (condition.code) {
                if (condition.code.coding && condition.code.coding[0].code && condition.code.text) {
                    let id = String(condition.code.coding[0].code).trim();
                    let text = String(condition.code.text).trim();

                    if (text.startsWith(id)) {
                        text = text.substr(id.length).trim();
                    }

                    text = text.replace(/  /gi, ' ')
                        .replace(/- -/, '-')
                        .replace(/--/gi, '-');

                    if (text.startsWith('-')) {
                        text = text.substr(1).trim();
                    }

                    if (id.endsWith('-')) id = id.substr(0, id.length - 1).trim();
                    if (id.endsWith('.')) id = id.substr(0, id.length - 1).trim();
                    if (id.endsWith('_ _')) id = id.substr(0, id.length - 3).trim();
                    if (id.endsWith('.')) id = id.substr(0, id.length - 1).trim();

                    this.icdList.push({id: id, text: text});
                } else {
                    if (condition.code.text) {
                        result.push(condition.code.text);
                    } else if (condition.code.coding && condition.code.coding[0]) {
                        result.push(`${condition.code.coding[0].code} - ${condition.code.coding[0].display}`);
                    }
                }
            }
        });

        return result;
    }

    protected async printStammblatt() {
        // RuntimeInfo.IsLoading = true;
        try {
            let anamnese = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
            let assessment = this.patient.latestAssessment;
            let anamneseBackup = NitTools.Clone(anamnese);
            let assessmentBackup = NitTools.Clone(assessment);

            if (anamnese && assessment) {
                if (!this.patient.isOffline) {
                    if (Fhir.QuestionnaireResponse.ResponseValuesDiffer(anamnese, anamneseBackup)) {
                        let newAnamnese = <any>await this.fhirService.update(anamnese);
                        PatientService.AddQuestionnaireResponse(this.patient, newAnamnese, true);
                    }

                    if (Fhir.QuestionnaireResponse.ResponseValuesDiffer(assessment, assessmentBackup)) {
                        let newAssessment = <any>await this.fhirService.update(assessment);
                        PatientService.AddQuestionnaireResponse(this.patient, newAssessment, true);
                        this.patient.latestAssessment = await QuestionnaireService.GetLatestResponseOfTypeAsync(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
                    }
                }

                ReportService.Preview(anamnese.id, this.formSetting.report.other["stammblatt"]);
                this.isLoading = false;
                this.processingPrint = false;                
            } else {
                let html = '';
                if (!assessment) html += '<div>' + this.i18n.tr('response_missing').replace('%QUESTIONNAIRE%', this.i18n.tr('assessment')) + '</div>';
                if (!anamnese) html += '<div>' + this.i18n.tr('response_missing').replace('%QUESTIONNAIRE%', this.i18n.tr('anamnesis')) + '</div>';

                this.dialogMessages.prompt(html, this.i18n.tr('print_requirements_not_met'), true);
                console.warn("No Anamnesis found to send");
            }
        } catch (e) {
            ReportService.ShowReportError(this.dialogService, e, this.i18n);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    protected async printPdf(routeName: string, isSecondPass: boolean = false) {
        await this.checkIfBIAndBiExAreValidated();
        if (!ReportService.ReportServer) return;
        let form = ConfigService.GetFormSettings(routeName);
        if (!form) {
            console.warn("Form with route '" + routeName + "' not found in setup.json");
            return;
        }

        let questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(form.questionnaireName);
        if (!questionnaire) {
            console.warn("Questionnaire with name '" + form.questionnaireName + "' (from route: '" + routeName + "' in setup.json) not found");
            return;
        }

        let response = QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaire.id);

        if (!response) {
            console.warn("No Response found to print");
            return;
        }

        // just to make sure never to land in an endless loop
        if (isSecondPass === true && response.status === fhirEnums.QuestionnaireResponseStatus.inProgress) {
            this.processingPrint = false;
            return;
        }

        if (!this.isResponsePrintable(response, () => {
            this.printPdf(routeName, true);
        })) return;

        try {
            let localName = await ReportService.GetReportByName(form.report.name);
            if (localName) {
                ReportService.Pdf(response.id, localName);
            }
        } catch (e) {
            ReportService.ShowReportError(this.dialogService, e, this.i18n);
        } finally {
            this.processingPrint = false;
        }
    }

    protected async printPreview(routeName: string, isSecondPass: boolean = false) {
        const cfg = ConfigService.GetFormSettings(routeName, this.patient);
        const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(cfg.questionnaireName);
        let response = NitTools.Clone(QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]));
        let report = cfg.report.name;
        
        if (response) {
            // just to make sure never to land in an endless loop
            if (isSecondPass === true && response.status === fhirEnums.QuestionnaireResponseStatus.inProgress) {
                RuntimeInfo.IsLoading = false;
                this.processingPrint = false;
                return;
            }

            if (!this.isResponsePrintable(response, () => {
                this.printPreview(routeName, true);
            })) return;

            ReportService.Preview(response.id, report);
            RuntimeInfo.IsLoading = false;
            this.processingPrint = false;
        }
    }

    protected isResponsePrintable(response: any, afterCheck: Function): boolean {
        /* if (this.patient.isOffline) {
            this.dialogMessages.prompt("Patient ist im Nur-Lesen-Modus. Validieren derzeit nicht möglich.", this.i18n.tr("information"), false);
            return false;
        } */

        if (response && (response.status !== 'completed' && response.status !== 'amended')) {
            let questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
            let msg = this.i18n.tr("analyse_no_print_status").replace("%NAME%", questionnaire.title);
            // prompt the user to validate the document first
            this.dialogMessages.prompt(msg, this.i18n.tr("information"))
                .whenClosed(result => {
                    if (!result.wasCancelled) {
                        let qId = Tools.StripId(response.questionnaire); // .reference.split('/')[1];

                        let resp: string;
                        switch (qId) {
                            case QuestionnaireService.__listResult.QBarthelIndexId:
                                resp = 'barthelIndex';
                                break;
                            case QuestionnaireService.__listResult.QBarthelIndexExId:
                                resp = 'barthelindexEx';
                                break;
                            case QuestionnaireService.__listResult.QFimId:
                                resp = 'fim';
                                break;
                            default:
                                alert("Could not map " + qId);
                                resp = undefined;
                                break;
                        }

                        this.openModalAnalyseWindow(resp, () => {
                            this.processingPrint = true;
                            // RuntimeInfo.IsLoading = true;
                            afterCheck();
                        }).catch(e => console.warn(e));
                    }
                }).catch(e => console.warn(e));

            return false;
        }

        return true;
    }

    protected async openModalAnalyseWindow(name: string, afterSave?: Function) {
        if (this.patient.isOffline) return;
        const currentTitle = BasicForm.pageTitle;
        this.currentQuestionnaire = undefined;
        this.currentResponse = undefined;

        const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        const form: IFormSetting = cfg.forms.find(o => o.route && o.route.toUpperCase() === name.toUpperCase());
        this.currentQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(form.questionnaireName);

        // assign the response here, to ensure the display of the right values
        this.currentResponse = await QuestionnaireResponse.SeekForAttachedResponse(this.patient, name, this.currentQuestionnaire.name);
        this.currentStatus = translations.translate(this.currentResponse.status);

        if (this.currentResponse.status === fhirEnums.QuestionnaireResponseStatus.completed)
            this.currentStatus = this.i18n.tr("validated");

        // open the modal dialog:
        this.dialogSettings = {
            grouplist: false,
            response: this.currentResponse,
            questionnaire: this.currentQuestionnaire,
            encounter: this.patient.encounter,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: this.currentStatus,
            saveText: this.i18n.tr("validate"),
            abortText: this.i18n.tr("abort"),
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            patient: this.patient,            
            data: {
                title: this.currentQuestionnaire.title || this.currentQuestionnaire.name
            }
        };

        return this.dialogService
            .open({viewModel: QuestionnaireDialogContainer, model: this.dialogSettings, lock: true})
            .whenClosed(result => {
                BasicForm.pageTitle = currentTitle;
                if (!result.wasCancelled) {  // user clicked on "Validate"
                    this.modalWindowSaveClicked(result.output, afterSave);
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    protected updateAssessmentValue(linkId: string, value: string, responseLinkId: string, response: any, questionnaire: any) {
        if (this.patient.isOffline) return;
        if (!this.patient.latestAssessment) {
            if (!this.patient.latestAssessment) {
                console.warn("No Latest Assessment found for the current Patient:", this.patient);
                return;
            }
        }

        let assessmentItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, linkId, true);
        if (assessmentItem) {
            assessmentItem.answer = [
                {
                    valueCoding: {
                        code: value,
                        display: value
                    }
                }
            ];
        }

        let linkItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, responseLinkId, true);
        linkItem.answer = [
            {
                valueReference: {
                    display: questionnaire.name,
                    reference: `${fhirEnums.ResourceType.questionnaireResponse}/${response.id}`
                }
            }
        ];
    }

    protected async modalWindowSaveClicked(result: any, afterSave?: Function) {
        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(result?.questionnaire);
        switch (questionnaire.name.toUpperCase()) {
            case 'CAREITFIM':
                // move the duplicated data back into the list
                let fimItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(result, "FIM_07_03");
                if (fimItem) {
                    let rf = this.riskFields.find(o => o.fieldname === "FIM_07_03");
                    const val = String(Fhir.QuestionnaireResponse.GetResponseItemValueInt(fimItem, 0));
                    rf.sum = val;
                    /*                    if (ConfigService.Debug) {
                                            console.debug("[Analyse] Updated field FIM_07_03 with " + rf.sum);
                                        } */
                    const risk = this.riskCodings.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash('FIM')));
                    if (risk) {
                        risk.code = val;
                        risk.display = val;
                        risk.version = this.analyzeService.analyzer._version;
                    }
                    this.fimText = Fhir.QuestionnaireResponse.GetResponseItemValue(fimItem);
                    this.updateAssessmentValue(fimItem.linkId, this.fimText, "FIM", result, questionnaire);
                } else {
                    this.fimText = "?";
                }

                break;
            case 'CAREITEXBARTHELINDEX':
            case 'CAREITBARTHELINDEXEX':
                // move the duplicated data back into the list
                let biExItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(result, "EBI_07");
                if (biExItem) {
                    this._biExtText = Fhir.QuestionnaireResponse.GetResponseItemValue(biExItem);
                    /*                    if (ConfigService.Debug) {
                                            console.debug("[Analyse] Updated field EBI_07 with " + this._biExtText);
                                        } */
                    const risk = this.riskCodings.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash('BarthelIndexEx')));
                    if (risk) {
                        risk.code = String(this._biExtText);
                        risk.display = String(this._biExtText);
                        risk.version = this.analyzeService.analyzer._version;
                    }

                    this.updateAssessmentValue(biExItem.linkId, this._biExtText, "BarthelIndexEx", result, questionnaire);
                }

                // save the resulting vdd in flags
                if (this.patient.flags && this.patient.latestAssessment && ['completed', 'amended'].indexOf(result.status) > -1) {
                    let vddItem = QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, 'risk_vdd', false);
                    if (vddItem) {
                        let vdd = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(vddItem));
                        const flagVdd = Fhir.Tools.GetOrCreateFlag(this.patient.flags, 'RiskVdd');
                        if (flagVdd) {
                            flagVdd.code = vdd ? 'true' : 'false';
                        }
                    }
                }

                break;
            case 'CAREITBARTHELINDEX':
                // move the duplicated data back into the list
                let biItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(result, "BI_11");
                if (biItem) {
                    this._biText = Fhir.QuestionnaireResponse.GetResponseItemValue(biItem);
                    const risk = this.riskCodings.find(o => o.system.endsWith('/' + NitTools.ExcludeTrailingSlash('BarthelIndex')));
                    if (risk) {
                        risk.code = String(this._biText);
                        risk.display = String(this._biText);
                        risk.version = this.analyzeService.analyzer._version;
                    }

                    this.updateAssessmentValue(biItem.linkId, this._biText, "BarthelIndex", result, questionnaire);
                }

                // save the resulting fall in flags
                if (this.patient.flags && this.patient.latestAssessment && ['completed', 'amended'].indexOf(result.status) > -1) {
                    let fallItem = QuestionnaireResponse.GetResponseItemByLinkId(this.patient.latestAssessment, 'risk_sturz', false);
                    if (fallItem) {
                        let flagFall = NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(fallItem));
                        const flag = Fhir.Tools.GetOrCreateFlag(this.patient.flags, 'RiskSturz');
                        if (flag) {
                            flag.code = flagFall ? 'true' : 'false';
                        }
                    }
                }

                break;
        }

        RuntimeInfo.IsLoading = true;

        if (UserService.Practitioner) {
            let display = undefined;
            if (UserService.Practitioner.name) {
                let name = UserService.Practitioner.name.find(o => o.use === 'official');
                if (!name) name = UserService.Practitioner.name.find(o => o.use === 'usual');
                if (!name) name = UserService.Practitioner.name[0];
                if (name) {
                    display = name.family + ', ' + name.given?.join(' ');
                }
            }

            result.author = {
                reference: `Practitioner/${UserService.Practitioner.id}`,
                display: display
            };
        }

        PatientService.AddQuestionnaireResponse(this.patient, result, true);
        PatientService.AddQuestionnaireResponse(this.patient, this.patient.latestAssessment, true);

        try {
            await AnalyzeService.ValidateMark5(this.patient, false);
            await this.checkIfBIAndBiExAreValidated();

            const bundle: any[] = [this.patient.latestAssessment, result, this.patient.currentRisks, this.patient.flags];
            await Fhir.Rest.Bundle(bundle, fhirEnums.HTTPVerb.put);
            // PatientService.AddQuestionnaireResponse(this.patient, result, true);
            this.notifier.notify(this.patient);
            if (typeof afterSave === "function") {
                afterSave(result);
            }
        } catch (e) {
            console.warn(e.message || e);
        } finally
        {
            RuntimeInfo.IsLoading = false;
        }
    }

    protected getSocialServiceUrl() {
        if (this.formSetting && this.formSetting.settings && typeof this.formSetting.settings["socialService"] === "string") {
            let s: string = this.formSetting.settings["socialService"];

            let user = UserService.UserName;
            let patientId = this.patient.id;
            if (this.patient.identifier) {
                let ident = this.patient.identifier.find(o => o.use === "official");
                if (!ident) ident = this.patient.identifier.find(o => o.value);
                if (ident) patientId = ident.value;
            }

            let encounterId = this.patient.encounter.id;
            if (this.patient.encounter.identifier) {
                let ident = this.patient.encounter.identifier.find(o => o.system?.indexOf('visitNumber') > -1);
                if (!ident) this.patient.encounter.identifier.find(o => o.system.endsWith('mappings/sourceId'));
                if (!ident) ident = this.patient.encounter.identifier.find(o => o.use === "official");
                if (!ident) ident = this.patient.encounter.identifier.find(o => o.value);
                if (ident) encounterId = ident.value;
            }

            let givenName = '';
            let familyName = '';
            let born: any = new Date(this.patient.birthDate);
            born = `${NitTools.ToString((<Date>born).getDate(), 2, '0')}.${NitTools.ToString((<Date>born).getMonth() + 1, 2, '0')}.${(<Date>born).getFullYear()}`;

            if (this.patient.name) {
                let name = this.patient.name.find(o => o.use === 'official');
                if (!name) name = this.patient.name.find(o => o.use === 'usual');
                if (!name) name = this.patient.name.find(o => o.family);

                if (name) {
                    try {
                        familyName = name.family;
                        givenName = name.given?.join(', ');
                    } catch (e) {
                        console.warn(e.message || e);
                    }
                }
            }

            s = s.replace("%USER%", encodeURIComponent(user))
                .replace("%PATIENTID%", encodeURIComponent(patientId))
                .replace("%ENCOUNTERID%", encodeURIComponent(encounterId))
                .replace("%BORN%", born)
                .replace("%PATIENTFAMILY%", encodeURIComponent(familyName))
                .replace("%PATIENTGIVEN%", encodeURIComponent(givenName));

            this.socialServiceUrl = s;
        }
    }

    protected getPKMSReasonsList(): Reason[] {
        return PKMSService.GetPKMSReasonsList(this.patient, this.qList);
    }

    protected async loadPKMSReasons() {
        this.groups = await PKMSService.Fetch();
        this.pkmsVisible = false;
        if (!this.patient.pkms_relevant) {
            // if (this.notifier) this.notifier.notify(this.patient);
            return;
        }

        let html = '';
        let reasonList = this.getPKMSReasonsList();
        let curGroupName = '';

        // DONETODO: punkt abschneiden ->  G10.2 nach G10 und dann neu konsolidieren für unique
        let tmpReasons = [];
        reasonList.forEach(reason => {
            let tmp = reason.reasonName;
            tmp = tmp.split('.')[0];

            if (reason.groupName.indexOf(':') > -1) {
                tmp = reason.groupName.split(':')[0] + '#' + tmp;
            }

            let r = tmpReasons.find(o => o.reasonName === tmp);

            if (typeof r === "undefined") {

                let text = reason.reasonDescription;
                let grp = this.groups.find(o => o.id === reason.groupName.split(':')[0].trim());
                if (grp) {
                    let grpReason = grp.profiles.find(o => o.reasonCode === tmp);
                    if (grpReason) {
                        text = grpReason.reasonCode + ": " + grpReason.shortText;
                    }
                }

                tmpReasons.push({
                    groupName: reason.groupName.trim(),
                    reasonName: tmp,
                    reasonDescription: text
                });
            }
        });

        tmpReasons.forEach(reason => {
            let name = reason.reasonName;
            if (name.indexOf('#') > -1) {
                name = name.split('#')[1];
            }

            if (curGroupName === '' || curGroupName !== reason.groupName) {
                if (html !== "") html += "<br />";
                html += `<span title="${reason.groupName}">${reason.groupName}</span>: `;

                html += ` <span class="span-hover" title="${reason.reasonDescription}">${name}</span>`;
                curGroupName = reason.groupName;
            } else {
                html += `, <span class="span-hover" title="${reason.reasonDescription}">${name}</span>`;
            }
        });


        // murat, Test-Station1, Witwer, karl-josef
        this.pkmsHtml = html;
        this.pkmsVisible = this.patient.isEncounterDuringPkmsPeriod;
    }

    protected async activate(params) {
        this.doSelfSave = true;
        this.qList = await QuestionnaireService.GetQuestionnaireIds();
        this.formSetting = ConfigService.GetFormSettings("analysis");
    }

    async startUp() {
        this.getSocialServiceUrl();
        const patient = PatientItem.SelectedPatient;
        if (!this.qList)
            this.qList = await QuestionnaireService.GetQuestionnaireIds();

        const assessmentName = PatientItem.GetQuestionnaireName(patient, 'assessment');
        const anamnesisName = PatientItem.GetQuestionnaireName(patient, 'anamnesis');
        const assessmentQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentName);
        const anamnesisQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(anamnesisName);

        let numAssesments = QuestionnaireService.GetResponsesOfType(patient, assessmentQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]).length;
        let numAnamnesis = QuestionnaireService.GetResponsesOfType(patient, anamnesisQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]).length;
        this.isAnalysePossible = numAssesments > 0 && numAnamnesis > 0;

        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        //let cfg = ConfigService.GetFormSettings('anamnesis');

        const latestAnam = QuestionnaireService.GetLatestResponseOfType(this.patient, anamnesisQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        if (latestAnam) {
            this.anamneseDate = new Date(latestAnam.authored);
        }

        const latestAss = QuestionnaireService.GetLatestResponseOfType(patient, assessmentQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);

        if (latestAss) {
            this.assessmentDate = new Date(latestAss.authored);
        }

        this.isAnalysePossible = typeof latestAnam !== "undefined" && typeof latestAss !== "undefined";

        if (this.isAnalysePossible) {
            await this.analyzeService.analyse(this.patient, latestAss);//  this.patient.latestAssessment);
            this.notifier.notify(this.patient);
            // this.analyzerVersion = await this.analyzeService.getAnalyzerVersion(this.patient, this.patient.latestAssessment);
            // if (ConfigService.Debug) console.debug("[analyse.ts] - startUp() result:)", result);

            this.pkmsVisible = true;
        }

        this.isLoading = false;
    }
}
